import { OreStack } from '@edenredespana/oreneta'
import { AlignPosition } from '../../../../../domain/enum'
import { useDistributorController } from '../../../Edenred/distributor/hook/useDistributorController'
import { DistributorsContainer } from '../../../Edenred/distributor/organism/DistributorsContainer'

export const FrequentOperationsContainerTR = (): JSX.Element => {
  const { getCardAndRechargeDistributorTR: getCardAndRechargeDistributor } =
    useDistributorController()

  return (
    <OreStack space="medium" direction="column">
      <DistributorsContainer
        position={AlignPosition.horizontal}
        distributors={getCardAndRechargeDistributor()}
      />
    </OreStack>
  )
}
