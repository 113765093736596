import { MouseEvent, useRef, useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  OreHeading,
  OreDivider,
  OreCardContainer,
  OreCardWrapper,
  OreStack,
  OreButton,
  SvgBin,
  OreTab,
  OreTabs,
} from '@edenredespana/oreneta'
import { PageWrapper } from 'src/presentation/layout'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import {
  ConfigureEmployeesBasicForm,
  ConfigureEmployeesRetributionForm,
  useConfigureEmployee,
  ConfigureEmployeesCommunicationEmail,
  RetributionCalculator,
  ConfigureEmployeesConditionsForm,
  ConfigureEmployeeProvider,
  EmployeeForm,
  ChangeCollectiveModal,
  ChangeCollectiveModalActions,
} from 'src/Flex/Employees/ui/employee-detail'
import { forms } from 'src/domain/translations'
import { useCollectiveController } from 'src/Flex/Collectives/ui/collective-list'
import { DeleteEmployeeModal, DeleteModalActions } from './atoms/DeleteEmployeeModal'
import { DownloadDocuments } from './molecules/DownloadDocuments'
import { NovationContractSignState } from 'src/domain'
import { EmployeeTabsEnum } from '../../domain/EmployeeTabsEnum'
import { ConfigureEmployeeProductList } from './organisms/ConfigureEmployeeProductList'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'

export const FlexEmployeeEditPageInner = (): JSX.Element => {
  const ref = useRef<DeleteModalActions>(null)
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()

  const { model, form, create, save, validateChangeCollective, ChangeCollectiveExcel } =
    useConfigureEmployee()
  const { collectives } = useCollectiveController(null)
  const [selectedTab, setSelectedTab] = useState<number>(EmployeeTabsEnum.prf)
  const [acceptChangeCollective, setAcceptChangeCollective] = useState<boolean>(false)
  const changeCollectiveRef = useRef<ChangeCollectiveModalActions>(null)

  const handleSave = (event: MouseEvent): void => {
    event.preventDefault()

    form.handleSubmit(async (data: EmployeeForm) => {
      const result = id ? await save(data, id) : await create(data)

      if (result) {
        navigate(-1)
      }
    })()
  }

  const handleValidateChangeCollective = (event: MouseEvent): void => {
    event.preventDefault()
    form.handleSubmit(async (data: EmployeeForm) => {
      if (id) {
        const validate = await validateChangeCollective(data, id)
        if (
          validate?.contractStateInfo &&
          validate.contractStateInfo.numberOfEmployeesContract &&
          validate.contractStateInfo.numberOfEmployeesContract > 0
        ) {
          changeCollectiveRef?.current?.open(
            validate.contractStateInfo.numberOfEmployeesContract,
            validate.showSSIAlert
          )
        } else {
          handleSave(event)
        }
      }
    })()
  }

  const handleDeleteEmployee = (
    employeeId: string,
    hasISaludActiveHires: boolean
  ): void => {
    ref?.current?.open(employeeId, hasISaludActiveHires)
  }

  const downloadFile = async () => {
    const excel = await ChangeCollectiveExcel(form.getValues(), id ?? '')
    const link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${excel}`
    link.download = t(flexProductsTranslations.employeeContractAlert.excel)
    link.click()
    link.remove()
  }

  return (
    <FormProvider {...form}>
      <form>
        <OreStack direction="row" placeContent="space-between">
          <OreHeading as="h1" size="title-md">
            {id
              ? t(flexEmployeesTranslations.configure.edit.title)
              : t(flexEmployeesTranslations.configure.create.title)}
          </OreHeading>
          {id ? (
            <OreButton
              category={'primary'}
              icon={<SvgBin />}
              size="large"
              onClick={() => handleDeleteEmployee(id, !!model?.hasISaludActiveHires)}>
              {t(flexEmployeesTranslations.delete.cta)}
            </OreButton>
          ) : null}
        </OreStack>

        {id ? (
          <div className="pb-2 pt-1">
            <OreTabs>
              <OreTab
                handleClick={() => setSelectedTab(EmployeeTabsEnum.prf)}
                active={selectedTab === EmployeeTabsEnum.prf}
                label={t(flexEmployeesTranslations.configure.tabs.prf)}
              />
              <OreTab
                handleClick={() => setSelectedTab(EmployeeTabsEnum.products)}
                active={selectedTab === EmployeeTabsEnum.products}
                label={t(flexEmployeesTranslations.configure.tabs.products)}
              />
            </OreTabs>
          </div>
        ) : (
          <OreDivider space="larger-bottom" />
        )}
        {selectedTab === EmployeeTabsEnum.prf && (
          <>
            <OreCardContainer>
              <OreCardWrapper>
                <OreStack space="2xlarge" placeContent="stretch">
                  <ConfigureEmployeesBasicForm />
                  <OreStack space="medium" placeContent="stretch">
                    <ConfigureEmployeesRetributionForm
                      model={model}
                      collectives={collectives}
                    />
                    <RetributionCalculator collectives={collectives} form={form} />
                  </OreStack>
                  {id ? null : <ConfigureEmployeesCommunicationEmail />}
                  {id &&
                    model &&
                    model.novationContractSignState ===
                      NovationContractSignState.Signed && (
                      <DownloadDocuments employeeId={id} />
                    )}
                  <ConfigureEmployeesConditionsForm />
                </OreStack>
              </OreCardWrapper>
            </OreCardContainer>

            <OreDivider space="larger-top" />
            <OreStack direction="row" placeContent="end" space="medium">
              <OreButton
                size="small"
                onClick={(): void => navigate(-1)}
                category="secondary">
                {t(forms.buttons.cancel)}
              </OreButton>
              <OreButton
                disabled={!!(!!id && !form.formState.isDirty)}
                type="submit"
                onClick={
                  form.formState.dirtyFields.collectiveId && !acceptChangeCollective && id
                    ? handleValidateChangeCollective
                    : !form.formState.dirtyFields.collectiveId ||
                      acceptChangeCollective ||
                      !id
                    ? handleSave
                    : handleValidateChangeCollective
                }
                size="small"
                category="primary">
                {id
                  ? t(flexEmployeesTranslations.configure.common.form.edit.label)
                  : t(flexEmployeesTranslations.cta_create)}
              </OreButton>
            </OreStack>
          </>
        )}
        {selectedTab === EmployeeTabsEnum.products && id && (
          <ConfigureEmployeeProductList employeeId={id} />
        )}
        <DeleteEmployeeModal ref={ref} />
        <ChangeCollectiveModal
          ref={changeCollectiveRef}
          downloadFile={downloadFile}
          setAcceptChangeCollective={setAcceptChangeCollective}
          handleSave={handleSave}
        />
      </form>
    </FormProvider>
  )
}

export const FlexEmployeeEditPage = (): JSX.Element => {
  const { id } = useParams()

  return (
    <ConfigureEmployeeProvider id={id}>
      <PageWrapper>
        <FlexEmployeeEditPageInner />
      </PageWrapper>
    </ConfigureEmployeeProvider>
  )
}
