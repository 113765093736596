import { OreStack } from '@edenredespana/oreneta'
import { AlignPosition } from '../../../../../domain/enum'
import type { DistributorState } from '../../../../../domain/models/cardOrder/distributors/DistributorModel'
import { Distributor } from '../molecules/Distributor'

interface Props {
  position: number
  distributors: DistributorState[]
}

export const DistributorsContainer = ({ position, distributors }: Props): JSX.Element => {
  return (
    <OreStack
      space="small"
      direction={position === AlignPosition.horizontal ? 'row' : 'column'}
      sameSize>
      {distributors.map((distributor: DistributorState, index: number) => {
        return <Distributor key={index} distributor={distributor} position={position} />
      })}
    </OreStack>
  )
}
