import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { flexEmployeesTranslations } from '../../translations'
import {
  OreButton,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
} from '@edenredespana/oreneta'
import { ChangeCollectiveMessage } from '../atoms/ChangeCollectiveMessage'
import { forms } from 'src/domain/translations'
import { ChangeCollectiveMessageSSI } from '../atoms/ChangeCollectiveMessageSSI'
import { set } from 'lodash'

export type ChangeCollectiveModalActions = {
  open: (numberOfEmployeesContract: number, isSSI: boolean) => void
}

interface IProps {
  ref: React.Ref<ChangeCollectiveModalActions>
  handleSave: (event: React.MouseEvent<Element, MouseEvent>) => void
  downloadFile: () => Promise<void>
  setAcceptChangeCollective: Dispatch<SetStateAction<boolean>>
}

export const ChangeCollectiveModal = forwardRef<ChangeCollectiveModalActions, IProps>(
  ({ handleSave, downloadFile, setAcceptChangeCollective }, ref): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false)
    const [employeesAffected, setEmployeesAffected] = useState<number>()
    const [isSSI, setIsSSI] = useState<boolean>(false)
    const { t } = useTranslation()

    useImperativeHandle(ref, () => ({
      open: async (numberOfEmployeesContract: number, isSSI: boolean): Promise<void> => {
        setEmployeesAffected(numberOfEmployeesContract)
        setIsSSI(isSSI)
        setOpen(true)
      },
    }))

    const saveAndClose = (event: React.MouseEvent<Element, MouseEvent>) => {
      handleSave(event)
      setAcceptChangeCollective(true)
      setOpen(false)
    }
    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="medium" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            {isSSI ? (
              <ChangeCollectiveMessageSSI
                downloadFile={async () => await downloadFile()}
                numberOfContracts={employeesAffected ?? 0}
              />
            ) : (
              <ChangeCollectiveMessage
                downloadFile={async () => await downloadFile()}
                numberOfContracts={employeesAffected ?? 0}
              />
            )}
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={() => setOpen(false)} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              onClick={event => saveAndClose(event)}
              size="small"
              category="danger">
              {t(flexEmployeesTranslations.changeCollective.confirm)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
