import { useContext, useState } from 'react'
import { TTOrderContext } from './TTOrderContext'
import { TTOrderModel, TTOrderSummaryForm } from 'src/domain/models/TTOrder/TTOrderModel'
import { browserStorageKeys, cardOrderTypeEnum } from 'src/domain/enum'
import { EmployeesWithLastCard, IncompleteOrderModel } from 'src/domain/models'
import { CardOrderResumeDTO } from 'src/domain/dto'

export const TTOrderProvider = ({ children }: { children: React.ReactNode }) => {
  const [ttOrders, setTTOrders] = useState<TTOrderModel[]>([])
  const [orderContext, setOrderContext] = useState<cardOrderTypeEnum>(0)
  const [ttSummaryForm, setTTSummaryForm] = useState<TTOrderSummaryForm>(
    {} as TTOrderSummaryForm
  )
  const [ttOrderCheckout, setTTOrderCheckout] = useState<CardOrderResumeDTO>(
    {} as CardOrderResumeDTO
  )

  const requiresRechargeDate = (): boolean => {
    return !!ttOrders.find(order => !!order.cardData.initialAmount)
  }

  const addTTOrder = (ttOrder: TTOrderModel): void => {
    setTTOrders(prevOrders => [...prevOrders, { ...ttOrder }])
  }

  const addTTOrders = (ttOrders: TTOrderModel[]) => {
    setTTOrders(prevOrders => [...prevOrders, ...ttOrders])
  }

  const updateTTOrder = (orderToEdit: TTOrderModel): void => {
    const index = ttOrders.findIndex(order => order.id === orderToEdit.id)
    const updateOrders: TTOrderModel[] = [...ttOrders]
    updateOrders[index] = orderToEdit
    setTTOrders(updateOrders)
  }

  const addOrUpdateTTOrders = (newTTOrders: TTOrderModel[]) => {
    if (ttOrders && ttOrders.length <= 0) {
      setTTOrders(newTTOrders)
      return
    }
    const updatedOrders: TTOrderModel[] = [...ttOrders]
    newTTOrders.forEach(newTTOrder => {
      const index = updatedOrders.findIndex(order => order.id === newTTOrder.id)
      if (index !== -1) {
        updatedOrders[index] = { ...updatedOrders[index], ...newTTOrder }
      } else {
        updatedOrders.push(newTTOrder)
      }
    })
    setTTOrders(updatedOrders)
  }

  const removeTTOrder = (id: string): void => {
    setTTOrders(tgdOrders => tgdOrders.filter(order => order.id !== id))
  }

  const clearTTOrders = (): void => {
    setTTOrders([] as TTOrderModel[])
  }

  const setTTSelectedEmployees = (employees: EmployeesWithLastCard[]): void => {
    sessionStorage.setItem(
      browserStorageKeys.ttSelectedEmployees,
      JSON.stringify(employees)
    )
  }

  const getTTSelectedEmployees = (): EmployeesWithLastCard[] => {
    const employees = sessionStorage.getItem(browserStorageKeys.ttSelectedEmployees)
    return employees ? JSON.parse(employees) : []
  }

  const setTTEmployeesToConfig = (employees: TTOrderModel[]): void => {
    sessionStorage.setItem(browserStorageKeys.ttOrderEmployees, JSON.stringify(employees))
  }

  const getTTEmployeesToConfig = (): TTOrderModel[] => {
    const employees = sessionStorage.getItem(browserStorageKeys.ttOrderEmployees)
    return employees ? JSON.parse(employees) : []
  }

  const addTTSummaryForm = (form: TTOrderSummaryForm): void => {
    const ttIncompleteOrder = sessionStorage.getItem(browserStorageKeys.ttOrderIncomplete)
    if (form.preOrderId === undefined) {
      const order: IncompleteOrderModel<TTOrderModel> = ttIncompleteOrder
        ? JSON.parse(ttIncompleteOrder)
        : {}
      form.preOrderId = order.preOrderId
    }
    setTTSummaryForm({ ...form })
  }

  const clearTTSummaryForm = (): void => {
    setTTSummaryForm({} as TTOrderSummaryForm)
  }

  const addTTOrderCheckout = (checkout: CardOrderResumeDTO): void => {
    setTTOrderCheckout({ ...checkout })
  }

  const clearTTOrderCheckout = (): void => {
    setTTOrderCheckout({} as CardOrderResumeDTO)
  }

  const context = {
    ttOrders,
    addTTOrder,
    addTTOrders,
    updateTTOrder,
    addOrUpdateTTOrders,
    removeTTOrder,
    clearTTOrders,
    setTTSelectedEmployees,
    getTTSelectedEmployees,
    setTTEmployeesToConfig,
    getTTEmployeesToConfig,
    addTTSummaryForm,
    clearTTSummaryForm,
    addTTOrderCheckout,
    clearTTOrderCheckout,
    ttSummaryForm,
    ttOrderCheckout,
    orderContext,
    setOrderContext,
    requiresRechargeDate,
  }

  return <TTOrderContext.Provider value={context}>{children}</TTOrderContext.Provider>
}

export const useTTOrder = () => useContext(TTOrderContext)
