import { SvgFile, SvgForm, SvgBin, OreText } from '@edenredespana/oreneta'
import { t } from 'i18next'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../../../../../config/constants/navigationRoutes'
import { cardOrderService } from '../../../../../../core/services'
import { getIncompleteOrdersMap } from '../../../../../../domain/adapters/cardOrder/getIncompleteOrdersMap'
import type { IncompleteOrdersRequestBody } from '../../../../../../domain/bodyRequest/IncompleteOrdersRequestBody'
import { deleteAction } from '../../../../../../domain/constants'
import type { PopupButtonModel } from '../../../../../../domain/customComponents/Popup'
import type {
  CaptionModel,
  HeaderModel,
  RowModel,
} from '../../../../../../domain/customComponents/table/TableModel'
import {
  NotificationSeverity,
  browserStorageKeys,
  cardOrderTypeEnum,
  cardRequestTypeId,
  cardType,
  documentType,
  edenredProducts,
  orderType,
} from '../../../../../../domain/enum'
import type { CardOrderModel, DeliverySiteModel } from '../../../../../../domain/models'
import type {
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
  FooterModel,
  TableEmptyModel,
} from '../../../../../../domain/customComponents/table'
import type {
  IncompleteOrderModel,
  IncompleteOrderModelResponse,
} from '../../../../../../domain/models/cardOrder/IncompleteOrderModel'
import { forms } from '../../../../../../domain/translations'
import { myOrders } from '../../../../../../domain/translations/cardOrder/myOrders'
import { useCardOrder } from '../../../../../context/cardOrder/CardOrderProvider'
import { useUser } from '../../../../../context/user/UserProvider'
import { useModalController } from '../../../../Edenred'
import { useLoader } from '../../../../../context/loader/LoaderProvider'
import { createOrderId, createUserId, orderUniqueId } from 'src/core/helpers'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { getProductIconByProductType } from 'src/Flex/Products/ui/shared/atoms/getProductIconByProductType'
import { TGDEmployeeAndChildsModel } from 'src/domain/models/TGDOrder/TGDOrderModel'
import { MapToTgdEmployeeAndChildren } from './MapEmployeeesChildren'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'

export interface ConfigurationState {
  caption: CaptionModel
  header: HeaderModel
  rows: RowModel[]
  footer: FooterModel
  setRows: Dispatch<SetStateAction<RowModel[]>>

  popupDeleteShow: boolean
  setShowPopupDelete: Dispatch<SetStateAction<boolean>>
  popupDeleteButtons: PopupButtonModel[]

  navigate: NavigateFunction
  filter: FilterModel
  setFilter: Dispatch<SetStateAction<FilterModel>>
  onClickFilter: () => void
  tableEmpty: TableEmptyModel
}

interface Filters {
  orderName?: string
  productCode?: number
  orderTypeId?: number
}

interface Props {
  showFilter: boolean
  pageSize: number
  showAllTotalRow?: boolean
  isExtended: boolean
  productCode?: edenredProducts
}

export const useIncompleteOrders = ({
  pageSize,
  isExtended,
  productCode,
}: Props): ConfigurationState => {
  const navigate = useNavigate()
  const { addOrders, clearAll, setCardOrderData } = useCardOrder()
  const { userDeliverySitesById } = useUser()
  const [incompleteOrders, setIncompleteOrders] = useState<IncompleteOrderModel[]>([])
  const [rows, setRows] = useState<RowModel[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const [footer, setFooter] = useState<FooterModel>({})
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState<FilterModel>({})
  const isServerSide = true
  const { startLoading, stopLoading } = useLoader()
  const [caption, setCaption] = useState<CaptionModel>({
    title: t(myOrders.incompleteOrders.table.caption.title),
    description: t(myOrders.incompleteOrders.table.caption.orders),
  })
  const { addNotification } = useNotification()

  const startQuery: IncompleteOrdersRequestBody = {
    paginationModel: {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
      sortDescending: true,
      orderByField: 'orderCreationDate',
    },
  }

  if (productCode) {
    startQuery.productCode = productCode
  }

  const [query, setQuery] = useState<IncompleteOrdersRequestBody>(startQuery)

  const {
    show: popupDeleteShow,
    buttons: popupDeleteButtons,
    setShow: setShowPopupDelete,
    setButtons: setButtonsDeletePopup,
  } = useModalController()

  const GetIncompleteOrders = (): Promise<IncompleteOrderModelResponse> => {
    startLoading()
    return new Promise<IncompleteOrderModelResponse>(resolve => {
      cardOrderService()
        .getIncompleteOrders(query)
        .then(response => {
          const incompleteOrdersMapped = getIncompleteOrdersMap(response.data.rows)
          setIncompleteOrders(incompleteOrdersMapped)
          setTotalRows(response.data.totalRows)
          resolve(response.data)
        })
        .catch(() =>
          addNotification(t(forms.errors.genericError), NotificationSeverity.error)
        )
        .finally(() => stopLoading())
    })
  }

  const header: HeaderModel = {
    headerLabel: [
      { label: t(myOrders.incompleteOrders.table.header.orderName) },
      {
        headerElement: <SvgFile />,
        label: t(myOrders.incompleteOrders.table.header.orderTypeDescription),
      },
      { label: t(myOrders.incompleteOrders.table.header.actions) },
    ],
  }

  const tableEmpty: TableEmptyModel = {
    firstText: t(myOrders.incompleteOrders.table.empty.message),
    colSpan: 5,
  }

  const onClickFilter = (): void => {
    // event.preventDefault()
    const tableFilters: Filters = getFilterValues()
    setQuery({
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
        sortDescending: true,
        orderByField: 'orderCreationDate',
      },
      orderName: tableFilters.orderName,
      productCode: tableFilters.productCode,
      orderTypeId: tableFilters.orderTypeId,
    })
    setPage(1)
  }

  const getFilterValues = (): Filters => {
    const tableFilters: Filters = {}
    if (filter.search && filter.search.textBox.value !== '')
      tableFilters.orderName = filter.search.textBox.value?.trim()
    if (filter.filterFields && filter.filterFields.length > 0) {
      Seq(filter.filterFields).forEach((fields: FilterFieldModel) => {
        Seq(fields.filterFields).forEach((field: FilterControlModel) => {
          if (field.name === 'productCode' && field.value !== '')
            tableFilters.productCode = Number(field.value)
          if (field.name === 'orderTypeId' && field.value !== '')
            tableFilters.orderTypeId = Number(field.value)
        })
      })
    }
    return tableFilters
  }

  const GetOrderTypesFilter = (): [string, number][] => {
    const options: [string, number][] = []
    Seq(incompleteOrders).forEach((incompleteOrder: IncompleteOrderModel) => {
      if (
        !options.find(
          (option: [string, number]) => option[1] === incompleteOrder.orderTypeId
        )
      )
        options.push([incompleteOrder.orderTypeDescription, incompleteOrder.orderTypeId])
    })
    return options
  }

  const getFilters = (): void => {
    setFilter({
      search: {
        textBox: {
          fields: ['orderName'],
          placeHolder: t(myOrders.filter.search.incompleteOrderPlaceHolder),
          value: '',
        },
        searchButton: t(myOrders.filter.search.button),
      },
      resetButton: t(myOrders.filter.resetButtons),
      filterButtonLabel: t(forms.filter.label),
      filterFields: [
        {
          formClass: 'form-atom form-atom--half',
          filterFields: [
            {
              filterType: 'comboBox',
              name: 'productCode',
              label: t(myOrders.filter.filterFields.product.label),
              placeHolder: t(myOrders.filter.filterFields.product.placeholder),
              value: '',
              options: [
                { options: [['Ticket restaurante', edenredProducts.ticketRestaurant]] },
              ],
            },
            {
              filterType: 'comboBox',
              name: 'orderTypeId',
              label: t(myOrders.filter.filterFields.orderType.label),
              placeHolder: t(myOrders.filter.filterFields.orderType.placeholder),
              value: '',
              options: [{ options: GetOrderTypesFilter() }],
            },
          ],
        },
      ],
    })
  }

  const getTotalAssignmentsByEmployee = (cardOrders: CardOrderModel[]): number => {
    const list: CardOrderModel[] = []
    cardOrders.forEach(cardOrder => {
      const exists = list.find(
        employee => employee.employeeData.document === cardOrder.employeeData.document
      )
      if (!exists) list.push(cardOrder)
    })
    return list.length
  }

  const getTotalOrder = (incompleteOrder: IncompleteOrderModel): string => {
    switch (incompleteOrder.orderTypeId) {
      case cardOrderTypeEnum.newAndDuplicate:
        return (
          incompleteOrder.cardOrders.length.toString() +
          ' ' +
          t(myOrders.lastOrders.orderTypes.cards)
        )
      case cardOrderTypeEnum.discharge:
        return (
          incompleteOrder.cardOrders.length.toString() +
          ' ' +
          t(myOrders.lastOrders.orderTypes.unload)
        )
      case cardOrderTypeEnum.recharge:
        return (
          incompleteOrder.cardOrders.length.toString() +
          ' ' +
          t(myOrders.lastOrders.orderTypes.charge)
        )
      case cardOrderTypeEnum.assignment:
        return (
          getTotalAssignmentsByEmployee(incompleteOrder.cardOrders) +
          ' ' +
          t(myOrders.lastOrders.orderTypes.assignments)
        )
      default:
        return ''
    }
  }

  const getRows = (): void => {
    const incompleteOrdersRows: RowModel[] = []
    Seq(incompleteOrders).forEach((incompleteOrder: IncompleteOrderModel) => {
      const orderType = t(incompleteOrder.orderTypeDescription)
      const lastOrderRow: RowModel = {
        rowId: incompleteOrder.preOrderId,
        valign: 'middle',
        cells: isExtended
          ? [
              {
                type: 'element',
                cellElement: (
                  <div>
                    <div>
                      <div style={{ float: 'left', paddingRight: '15px' }}>
                        {getProductIconByProductType(incompleteOrder.productCode)}
                      </div>
                      <OreText bold style={{ paddingTop: '3px' }}>
                        {incompleteOrder.orderName}
                      </OreText>
                    </div>
                  </div>
                ),
              },
              {
                type: 'element',
                cellElement: (
                  <div>
                    <SvgForm style={{ float: 'left' }} />
                    <OreText>{orderType}</OreText>
                  </div>
                ),
              },
              {
                type: 'actions',
                cellActions: [
                  {
                    name: 'delete',
                    icon: <SvgBin />,
                    type: 'iconButton',
                    tooltip: t(forms.buttons.delete),
                    onClick: onClickDelete,
                  },
                  {
                    name: 'complete',
                    type: 'button',
                    onClick: () => completeOrder(incompleteOrder),
                    category: 'secondary',
                    text: 'Completar',
                  },
                ],
              },
            ]
          : [
              {
                type: 'element',
                cellElement: getProductIconByProductType(incompleteOrder.productCode),
              },
              {
                type: 'text',
                cellText: [
                  {
                    text: incompleteOrder.orderName,
                    name: 'orderName',
                    size: 'text-sm',
                    isBold: true,
                  },
                  {
                    text: getTotalOrder(incompleteOrder),
                    name: 'orderTypeDescription',
                    size: 'text-sm',
                  },
                ],
              },
              {
                type: 'actions',
                cellActions: [
                  {
                    name: 'delete',
                    icon: <SvgBin />,
                    type: 'iconButton',
                    tooltip: t(forms.buttons.delete),
                    onClick: onClickDelete,
                  },
                  {
                    name: 'complete',
                    type: 'button',
                    onClick: () => completeOrder(incompleteOrder),
                    category: 'secondary',
                    text: 'Completar',
                  },
                ],
              },
            ],
      }
      incompleteOrdersRows.push(lastOrderRow)
    })
    setRows(incompleteOrdersRows)
  }

  const onClickDelete = (preOrderId: number): void => {
    setShowPopupDelete(true)
    setButtonsDeletePopup(getCancelModalButtons(preOrderId))
  }

  const getCancelModalButtons = (preOrderId: number): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowPopupDelete(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'danger',
        onClick: () => {
          deletePreOrder(preOrderId)
        },
        size: 'large',
      },
    ]
  }

  const deletePreOrder = (preOrderId: number): void => {
    if (preOrderId) {
      setShowPopupDelete(false)
      startLoading()
      cardOrderService()
        .deletePreOrder(preOrderId)
        .then(() => {
          GetIncompleteOrders()
        })
        .catch(() =>
          addNotification(t(forms.errors.genericError), NotificationSeverity.error)
        )
        .finally(() => {
          stopLoading()
          setShowPopupDelete(false)
        })
    }
  }

  const completeOrderTR = (incompleteOrder: IncompleteOrderModel): void => {
    clearAll()
    if (incompleteOrder && incompleteOrder.cardOrders.length > 0) {
      Seq(incompleteOrder.cardOrders).forEach((order: CardOrderModel) => {
        order.preOrderId = incompleteOrder?.preOrderId
        if (incompleteOrder && order.cardData) {
          order.orderTypeId = incompleteOrder?.orderTypeId
          order.orderId = orderUniqueId()
          if (
            order.cardData.cardRequestTypeId &&
            order.cardData.cardRequestTypeId >= cardRequestTypeId.newPlastic
          ) {
            order.cardData.cardTypeId =
              order.cardData.cardRequestTypeId < cardRequestTypeId.newPlasticless
                ? cardType.Plastic
                : cardType.Virtual
          }
        }
        if (incompleteOrder && order.sendData) {
          userDeliverySitesById(order.sendData.deliverySiteId).then(
            deliverySiteResponse => {
              if (deliverySiteResponse && order.sendData) {
                order.sendData.alias = deliverySiteResponse.alias
                order.sendData.city = deliverySiteResponse.city
                order.sendData.contactName = deliverySiteResponse.contactName
                order.sendData.deliverySiteId = deliverySiteResponse.deliveryPointId
                order.sendData.deliveryTypeId = deliverySiteResponse.deliveryType
                order.sendData.email = deliverySiteResponse.email
                order.sendData.name = deliverySiteResponse.deliveryPointName
                order.sendData.observation = deliverySiteResponse.observation
                order.sendData.province = deliverySiteResponse.province
                order.sendData.streetDescription = deliverySiteResponse.streetDescription
                order.sendData.streetName = deliverySiteResponse.streetName
                order.sendData.streetNumber = deliverySiteResponse.streetNumber
                order.sendData.telephone = deliverySiteResponse.telephone
                order.sendData.zipCode = deliverySiteResponse.zipCode
              }
            }
          )
        }
      })
      setCardOrderData({ ...incompleteOrder })
      addOrders(incompleteOrder.cardOrders)
    }

    if (incompleteOrder && incompleteOrder.orderTypeId === orderType.Card)
      navigate(navigationRoutes.cardOrderHome)
    if (incompleteOrder && incompleteOrder.orderTypeId === orderType.Recharge)
      navigate(navigationRoutes.rechargeHome)
    if (incompleteOrder && incompleteOrder.orderTypeId === orderType.Unload)
      navigate(navigationRoutes.unloadHome)
  }

  const completeOrderTT = (incompleteOrder: IncompleteOrderModel): void => {
    const ttOrdersArray: TTOrderModel[] = []

    if (incompleteOrder && incompleteOrder.cardOrders.length > 0) {
      Seq(incompleteOrder.cardOrders).forEach((order: CardOrderModel) => {
        order.preOrderId = incompleteOrder?.preOrderId
        const ttOrderItem: TTOrderModel = {
          userId: createUserId(),
          id: createOrderId(),
          orderTypeId: incompleteOrder?.orderTypeId,
          companyId: order.employeeData.companyId || 0,
          employeeNumber: order.employeeData.employeeNumber || '',
          costCenter: order.employeeData.costCenter || '',
          telephone: order.employeeData.telephone || '',
          corporativeEmail: order.employeeData.email || '',
          birthDate: order.employeeData.birthDate || '',
          document: order.employeeData.document || '',
          documentTypeId: order.employeeData.documentTypeId || documentType.Default,
          secondSurname: order.employeeData.secondSurname || '',
          firstSurname: order.employeeData.firstSurname || '',
          name: order.employeeData.name || '',

          cardData: {
            initialAmount: order.cardData.initialAmount,
            cardRequestType:
              order.cardData.cardRequestTypeId || cardRequestTypeId.default,
            cardRequestTypeId:
              order.cardData.cardRequestTypeId || cardRequestTypeId.default,
            productTypeId: order.cardData.productTypeId,
          },

          deliverySiteId: order.sendData?.deliverySiteId || 0,
          // Delivery site data will be fetched for showing purposes in the summary
          deliverySiteData: {} as DeliverySiteModel,
        }

        ttOrdersArray.push(ttOrderItem)
      })

      const ttIncompleteOrder: IncompleteOrderModel<TTOrderModel> = {
        ...incompleteOrder,
        cardOrders: ttOrdersArray,
      }

      sessionStorage.setItem(
        browserStorageKeys.ttOrderComplete,
        JSON.stringify(ttIncompleteOrder)
      )

      sessionStorage.setItem(
        browserStorageKeys.ttOrderIncomplete,
        JSON.stringify(ttIncompleteOrder)
      )

      if (incompleteOrder && incompleteOrder.orderTypeId === orderType.Card)
        navigate(navigationRoutes.ttOrderSummary)
      if (incompleteOrder && incompleteOrder.orderTypeId === orderType.Recharge)
        navigate(navigationRoutes.ttRechargeSummary)
      if (incompleteOrder && incompleteOrder.orderTypeId === orderType.Unload)
        navigate(navigationRoutes.ttUnloadSummary)
    }
  }

  const completeOrderTGD = (incompleteOrder: IncompleteOrderModel): void => {
    const tgdEmployeesAndChilds: TGDEmployeeAndChildsModel[] =
      MapToTgdEmployeeAndChildren(incompleteOrder)
    sessionStorage.setItem(
      browserStorageKeys.tgdOrderComplete,
      JSON.stringify(tgdEmployeesAndChilds)
    )
    sessionStorage.setItem(
      browserStorageKeys.tgdPreOrderId,
      incompleteOrder.preOrderId.toString()
    )
    sessionStorage.setItem(
      browserStorageKeys.tgdOrderIncomplete,
      JSON.stringify(incompleteOrder)
    )
    navigate(navigationRoutes.tgdOrderSummary)
  }

  const completeOrder = (incompleteOrder: IncompleteOrderModel): void => {
    if (incompleteOrder.productCode === edenredProducts.ticketRestaurant) {
      completeOrderTR(incompleteOrder)
    }
    if (incompleteOrder.productCode === edenredProducts.ticketTransporte) {
      completeOrderTT(incompleteOrder)
    }
    if (incompleteOrder.productCode === edenredProducts.ticketGuarderia) {
      completeOrderTGD(incompleteOrder)
    }
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
        sortDescending: true,
        orderByField: 'orderCreationDate',
      },
    })
    setPage(page)
  }

  const getFooter = (): void => {
    setFooter({
      pagination: {
        onPageChange: onPageChange,
        labelNextPage: t(forms.pagination.next),
        labelPreviousPage: t(forms.pagination.back),
        rowsPerPage: pageSize,
        text: t(forms.pagination.of),
        page: page,
        isServerSide: isServerSide,
        totalRows: totalRows,
      },
    })
  }

  useEffect(() => {
    GetIncompleteOrders()
  }, [query])

  useEffect(() => {
    getRows()
    getFooter()
  }, [incompleteOrders])

  useEffect(() => {
    if (rows.length > 0) {
      setCaption(prevCaption => {
        return {
          ...prevCaption,
          rowCount: totalRows,
        }
      })
      getFilters()
    }
  }, [rows])

  return {
    caption,
    header,
    rows,
    setRows,
    popupDeleteShow,
    setShowPopupDelete,
    popupDeleteButtons,
    navigate,
    footer,
    filter,
    setFilter,
    onClickFilter,
    tableEmpty,
  }
}
