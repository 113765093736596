import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction, useEffect, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLabelModel, HeaderModel } from 'src/domain/customComponents/table'
import { currency, NotificationSeverity, queryParam } from 'src/domain/enum'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { OreCheckbox, SvgInfo } from '@edenredespana/oreneta'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import {
  forms,
  spendingRuleFormTranslation,
  unloadsConfigurationTranslations,
} from 'src/domain/translations'
import { useModalController } from 'src/presentation/components/Edenred'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { usePreviousURL } from 'src/presentation/components/Edenred/navigation/hooks'
import { CardOrderModel } from 'src/domain/models'
import { useCardOrder } from 'src/presentation/context/cardOrder/CardOrderProvider'

export interface EmployeesTROrderFormModel {
  employeesTR: CardOrderModel[]
}

export interface UnloadConfigurationEmployeeState {
  header: HeaderModel
  totalRows: number
  employeesTR: CardOrderModel[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  form: UseFormReturn<EmployeesTROrderFormModel, any>
  addConfigToOrder(): void
  selectedEmployees: CardOrderModel[]
  setSelectedEmployees: Dispatch<SetStateAction<CardOrderModel[]>>
  onSelectEmployee(event: ChangeEvent<HTMLInputElement>, employee: CardOrderModel): void
  isSelected(employee: CardOrderModel): boolean
  showSelected: boolean
  setShowSelected: Dispatch<SetStateAction<boolean>>
  onAssignMultipleValues: (initialAmount: string, costCenter: string) => void
  showCancelModal: boolean
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  errorMessage: string
  updateDataRowToTROrderEmployee: (
    userId: number,
    initialAmount: string,
    costCenter: string
  ) => void
  unloadAllBalance: (userId?: number) => void
}

export const useUnloadConfigurationEmployees = (): UnloadConfigurationEmployeeState => {
  const { t } = useTranslation()
  const {
    setTRSelectedEmployees,
    setTREmployeesToConfig,
    getTREmployeesToConfig,
    addOrUpdateOrders,
    orders,
    updateOrder,
    clearOrders,
  } = useCardOrder()
  const [page, setPage] = useState(1)
  const pageSize = 8
  const [employeesTR, setEmployeesTR] = useState<CardOrderModel[]>(
    getTREmployeesToConfig()
  )
  const [totalRows, setTotalRows] = useState<number>(0)
  const navigate = useNavigate()
  const [selectedEmployees, setSelectedEmployees] = useState<CardOrderModel[]>([])
  const [showSelected, setShowSelected] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const { addNotification } = useNotification()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams()

  const idTREditOrder = searchParams.get(queryParam.id)

  const comesFromSummary = usePreviousURL(navigationRoutes.unloadHome)

  useEffect(() => {
    if (idTREditOrder) {
      const orderToEdit = orders.find(order => order.orderId.toString() === idTREditOrder)
      if (orderToEdit) {
        setTREmployeesToConfig([{ ...orderToEdit }])
        setEmployeesTR([{ ...orderToEdit }])
      }
    }
  }, [idTREditOrder])

  const {
    show: showCancelModal,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const getDiffBetweenEmployeeArrays = (
    array1: CardOrderModel[],
    array2: CardOrderModel[]
  ): CardOrderModel[] => {
    let result: CardOrderModel[] = []
    result = array1.filter(emp1 => {
      return !array2.some(emp2 => {
        return emp1.employeeData.userId === emp2.employeeData.userId
      })
    })
    return result
  }

  const headerLabel: HeaderLabelModel[] = [
    { label: t(unloadsConfigurationTranslations.table.header.name) },
    {
      label: t(unloadsConfigurationTranslations.table.header.amount),
      tooltip: t(unloadsConfigurationTranslations.table.header.amountTooltip),
      tooltipIcon: <SvgInfo />,
    },
    {
      label: t(unloadsConfigurationTranslations.table.header.unload),
      tooltip: t(unloadsConfigurationTranslations.table.header.unloadTooltip),
      tooltipIcon: <SvgInfo />,
    },
    { label: t(unloadsConfigurationTranslations.table.header.costs) },
    { label: '' },
  ]

  const headerElement: JSX.Element = (
    <OreCheckbox
      name="check"
      checked={
        selectAll ||
        getDiffBetweenEmployeeArrays(employeesTR, selectedEmployees).length <= 0
      }
      onChange={event => {
        onSelectAllEmployees(event)
      }}
      disabled={showSelected}
    />
  )

  const header: HeaderModel = !idTREditOrder
    ? {
        headerElement: headerElement,
        headerLabel: headerLabel,
      }
    : { headerLabel: headerLabel }

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const schema = Yup.object({
    employeesTR: Yup.array().of(
      Yup.object().shape({
        costCenter: Yup.string().nullable().max(50, t(forms.errors.maxAllowed)),
        cardData: Yup.object().shape({
          initialAmount: Yup.number()
            .transform(value => (Number.isNaN(value) ? null : value))
            .nullable()
            .required(t(forms.errors.fieldRequired))
            .min(
              0.1,
              `${t(
                spendingRuleFormTranslation.form.errors.amountMustBeGreaterThanOrEqual
              )} ${0.1 + currency.euro}`
            )
            .max(
              1500,
              `${t(
                spendingRuleFormTranslation.form.errors.amountMustBeLessThanOrEqual
              )}  ${1500 + currency.euro}`
            )
            .test(
              'decimal-max-2-places',
              t(spendingRuleFormTranslation.form.errors.amountMustContainMaxTwoDecimals),
              value => {
                const isNullable = value === null || value === undefined
                if (isNullable) {
                  return true
                }
                const regex = /^\d+(\.\d{1,2})?$/
                return regex.test(value?.toString())
              }
            ),
        }),
      })
    ),
  })

  const form = useForm<EmployeesTROrderFormModel>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      employeesTR: employeesTR,
    },
  })

  const addConfigToOrder = (): void => {
    addOrUpdateOrders([...employeesTR])
    setTRSelectedEmployees([])
    setTREmployeesToConfig([])
    navigate(navigationRoutes.unloadHome)
  }

  const onSelectEmployee = (
    event: ChangeEvent<HTMLInputElement>,
    employee: CardOrderModel
  ): void => {
    if (event.target.checked) {
      const exist = selectedEmployees.find(
        emp => emp.employeeData.userId == employee.employeeData.userId
      )
      if (!exist) {
        setSelectedEmployees(prev => [...prev, employee])
      }
    } else {
      setSelectedEmployees(
        selectedEmployees.filter(
          emp => emp.employeeData.userId !== employee.employeeData.userId
        )
      )
    }
  }

  const onSelectAllEmployees = (event: ChangeEvent<HTMLInputElement>): void => {
    const selectedArray: CardOrderModel[] = []
    setSelectAll(event.target.checked)
    if (event.target.checked) {
      const difference = getDiffBetweenEmployeeArrays(employeesTR, selectedEmployees)
      selectedArray.push(...difference)
      if (selectedArray.length > 0)
        setSelectedEmployees(prev => [...prev, ...selectedArray])
    } else {
      selectedArray.push(
        ...selectedEmployees.filter((emp: CardOrderModel) => {
          return !employeesTR.some((e: CardOrderModel) => {
            return emp.employeeData.userId === e.employeeData.userId
          })
        })
      )
      setSelectedEmployees([...selectedArray])
    }
  }

  const isSelected = (employee: CardOrderModel): boolean => {
    const exist = selectedEmployees.find(
      emp => emp.employeeData.userId == employee.employeeData.userId
    )
    return !!exist
  }

  const onAssignMultipleValues = (initialAmount: string, costCenter: string): void => {
    const copyList = [...employeesTR]
    copyList.map((employee, index) => {
      if (selectedEmployees.includes(employee)) {
        if (initialAmount && initialAmount !== '') {
          employee.cardData.initialAmount = +initialAmount
          form.setValue(
            `employeesTR.${index + (page - 1) * pageSize}.cardData.initialAmount`,
            +initialAmount
          )
        }
        if (costCenter) {
          employee.employeeData.costCenter = costCenter
          form.setValue(
            `employeesTR.${index + (page - 1) * pageSize}.employeeData.costCenter`,
            costCenter
          )
        }
        return employee
      }
    })
    setEmployeesTR(copyList)
    setDefaultValuesAll()

    if (
      selectAll ||
      getDiffBetweenEmployeeArrays(employeesTR, selectedEmployees).length <= 0
    ) {
      form.trigger()
    }
  }

  const onClickCancel = (): void => {
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.discard),
        category: 'primary',
        onClick: () => {
          setTREmployeesToConfig([])
          setTRSelectedEmployees([])
          setShowCancelModal(false)
          if (!comesFromSummary) {
            clearOrders()
            addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
            navigate(navigationRoutes.myOrders)
          } else {
            navigate(navigationRoutes.unloadHome)
          }
        },
        size: 'large',
      },
    ]
  }

  const updateDataRowToTROrderEmployee = (
    userId: number,
    initialAmount: string,
    costCenter: string
  ): void => {
    const indexEmployeeToConfig: number = employeesTR.findIndex(
      (o: CardOrderModel) => o.employeeData.userId === userId
    )
    const employeeOnOrder = orders.find(
      (o: CardOrderModel) => o.employeeData.userId === userId
    )

    if (indexEmployeeToConfig >= 0) {
      const updatedOrders: CardOrderModel[] = [...employeesTR]
      const employee = { ...updatedOrders[indexEmployeeToConfig] }
      if (initialAmount) employee.cardData.initialAmount = +initialAmount
      if (costCenter) employee.employeeData.costCenter = costCenter
      updatedOrders[indexEmployeeToConfig] = employee
      setEmployeesTR(updatedOrders)
    }

    if (employeeOnOrder) {
      if (initialAmount) employeeOnOrder.cardData.initialAmount = +initialAmount
      if (costCenter) employeeOnOrder.employeeData.costCenter = costCenter
      updateOrder(employeeOnOrder)
    }
  }

  const setDefaultValuesAll = () => {
    employeesTR.forEach((employee, index) => {
      if (employee.cardData.initialAmount)
        form.setValue(
          `employeesTR.${index}.cardData.initialAmount`,
          employee.cardData.initialAmount
        )
      if (employee.employeeData.costCenter)
        form.setValue(
          `employeesTR.${index}.employeeData.costCenter`,
          employee.employeeData.costCenter
        )
    })
  }

  const setDefaultValuesByPage = () => {
    const start = (page - 1) * pageSize
    const end = start + pageSize
    const pageEmployees = employeesTR.slice(start, end)
    pageEmployees.forEach((employee, index) => {
      if (employee.cardData.initialAmount) {
        form.setValue(
          `employeesTR.${index + (page - 1) * pageSize}.cardData.initialAmount`,
          employee.cardData.initialAmount
        )
      }
      if (employee.employeeData.costCenter) {
        form.setValue(
          `employeesTR.${index + (page - 1) * pageSize}.employeeData.costCenter`,
          employee.employeeData.costCenter
        )
      } else {
        form.setValue(
          `employeesTR.${index + (page - 1) * pageSize}.employeeData.costCenter`,
          ''
        )
      }
    })
  }

  const unloadAllBalance = (userId?: number): void => {
    if (employeesTR && employeesTR.length <= 0) return

    if (userId) {
      const updatedSingleEmployeeTR = employeesTR.map((emp, index) => {
        if (emp.employeeData.userId === userId) {
          const balance = emp.cardData.balance
          const value = balance ? (balance.includes('-') ? 0 : +balance) : 0

          form.setValue(`employeesTR.${index}.cardData.initialAmount`, value)
          form.trigger(`employeesTR.${index}.cardData.initialAmount`)

          return {
            ...emp,
            cardData: {
              ...emp.cardData,
              initialAmount: value,
            },
          }
        } else return emp
      })

      setEmployeesTR(updatedSingleEmployeeTR)
    } else {
      const updatedEmployeesTR = employeesTR.map((emp, index) => {
        const balance = emp.cardData.balance
        const value = balance ? (balance.includes('-') ? 0 : +balance) : 0

        form.setValue(`employeesTR.${index}.cardData.initialAmount`, value)
        form.trigger(`employeesTR.${index}.cardData.initialAmount`)

        return {
          ...emp,
          cardData: {
            ...emp.cardData,
            initialAmount: value,
          },
        }
      })

      setEmployeesTR(updatedEmployeesTR)
    }
  }

  useEffect(() => {
    if (selectedEmployees.length === 0) setShowSelected(false)
  }, [selectedEmployees])

  useEffect(() => {
    setTotalRows(employeesTR.length)
    setDefaultValuesByPage()
  }, [employeesTR])

  useEffect(() => {
    setDefaultValuesByPage()
  }, [page])

  return {
    header,
    totalRows,
    employeesTR,
    page,
    setPage,
    pageSize,
    onPageChange,
    form,
    selectedEmployees,
    setSelectedEmployees,
    addConfigToOrder,
    onSelectEmployee,
    isSelected,
    showSelected,
    setShowSelected,
    onAssignMultipleValues,
    showCancelModal,
    setShowCancelModal,
    cancelPopupButtons,
    onClickCancel,
    errorMessage,
    updateDataRowToTROrderEmployee,
    unloadAllBalance,
  }
}
