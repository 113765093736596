import {
  SvgDistributorCard,
  SvgDistributorDownload,
  SvgDistributorRecharge,
  SvgExcel,
  SvgForm,
} from '@edenredespana/oreneta'
import { t } from 'i18next'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import type { DistributorState } from '../../../../../domain/models/cardOrder/distributors/DistributorModel'
import { orderHomeTranslation } from '../../../../../domain/translations/cardOrder/home'
import SvgRepeat from '../../../../assets/img/RepeatOrder.svg'

interface ConfigurationState {
  getAllDistributorsTR(): DistributorState[]
  getAllDistributorsTR1(): DistributorState[]
  getAllDistributorsTR2(): DistributorState[]
  getCardAndRechargeDistributorTR(): DistributorState[]
  getTGDPlusDistributor(): DistributorState[]
  getAllDistributorsTGDBasic(): DistributorState[]
  getAllDistributorsTT(): DistributorState[]
  getAllDistributorsTT1(): DistributorState[]
  getAllDistributorsTT2(): DistributorState[]
  getCardAndRechargeDistributorTT(): DistributorState[]
}

export const useDistributorController = (): ConfigurationState => {
  // TR

  const rechargeDistributorTR: DistributorState = {
    title: t(orderHomeTranslation.distributors.recharge.title),
    subtitle: t(orderHomeTranslation.distributors.recharge.subtitle),
    icon: <SvgDistributorRecharge />,
    childs: [
      {
        title: t(orderHomeTranslation.distributors.recharge.newCardExcel),
        link: navigationRoutes.cardOrderRechargeExcel,
        icon: <SvgExcel />,
      },
      {
        title: t(orderHomeTranslation.distributors.recharge.newCardForm),
        link: navigationRoutes.rechargeCard,
        icon: <SvgForm />,
      },
    ],
  }

  const cardDistributorTR: DistributorState = {
    title: t(orderHomeTranslation.distributors.newCard.title),
    subtitle: t(orderHomeTranslation.distributors.newCard.subtitle),
    icon: <SvgDistributorCard />,
    childs: [
      {
        title: t(orderHomeTranslation.distributors.newCard.newCardExcel),
        link: navigationRoutes.cardOrderNewExcel,
        icon: <SvgExcel />,
      },
      {
        title: t(orderHomeTranslation.distributors.newCard.newCardForm),
        link: navigationRoutes.cardOrderNew,
        icon: <SvgForm />,
      },
      {
        title: t(orderHomeTranslation.distributors.newCard.newDuplicated),
        link: navigationRoutes.duplicatedCard,
        icon: <SvgForm />,
      },
    ],
  }

  const unloadDistributorTR: DistributorState = {
    title: t(orderHomeTranslation.distributors.unload.title),
    subtitle: t(orderHomeTranslation.distributors.unload.subtitle),
    icon: <SvgDistributorDownload />,
    childs: [
      {
        title: t(orderHomeTranslation.distributors.unload.newUnloadExcel),
        link: navigationRoutes.cardOrderUnloadExcel,
        icon: <SvgExcel />,
      },
      {
        title: t(orderHomeTranslation.distributors.unload.newUnloadForm),
        link: navigationRoutes.unloadCard,
        icon: <SvgForm />,
      },
    ],
  }

  const repeatOrderDistributor: DistributorState = {
    title: t(orderHomeTranslation.distributors.repeat.title),
    subtitle: t(orderHomeTranslation.distributors.repeat.subtitle),
    icon: <img src={SvgRepeat} />,
    link: navigationRoutes.myOrdersHistory,
    childs: [],
  }

  // TGD Plus

  const kindergartenPlusDistributor: DistributorState = {
    title: t(orderHomeTranslation.distributors.kindergarten.title),
    subtitle: t(orderHomeTranslation.distributors.kindergarten.subtitle),
    icon: <SvgDistributorRecharge />,
    childs: [
      {
        title: t(orderHomeTranslation.distributors.kindergarten.newOrderExcel),
        link: navigationRoutes.tgdOrderExcel,
        icon: <SvgExcel />,
      },
      {
        title: t(orderHomeTranslation.distributors.kindergarten.newEmployeeWithSonForm),
        link: navigationRoutes.tgdOrderForm,
        icon: <SvgForm />,
      },
      {
        title: t(orderHomeTranslation.distributors.kindergarten.newSonForm),
        link: navigationRoutes.tgdOrderSelectSingleEmployee,
        icon: <SvgForm />,
      },
      {
        title: t(orderHomeTranslation.distributors.kindergarten.kindergartenForm),
        link: navigationRoutes.tgdOrderSelectMultipleEmployee,
        icon: <SvgForm />,
      },
    ],
  }

  // TGD Basic

  const assignAndRegisterDistributorTGDBasic: DistributorState = {
    title: t(orderHomeTranslation.distributors.assignAndRegister.title),
    subtitle: t(orderHomeTranslation.distributors.assignAndRegister.subtitle),
    icon: <SvgDistributorRecharge />,
    childs: [
      {
        title: t(
          orderHomeTranslation.distributors.assignAndRegister.AssignmentAndNewExcel
        ),
        link: '#',
        icon: <SvgExcel />,
      },
      {
        title: t(
          orderHomeTranslation.distributors.assignAndRegister.newEmployeeAndChildForm
        ),
        link: navigationRoutes.tgdBasicOrderForm,
        icon: <SvgForm />,
      },
      {
        title: t(orderHomeTranslation.distributors.assignAndRegister.newChildForm),
        link: '#',
        icon: <SvgForm />,
      },
      {
        title: t(orderHomeTranslation.distributors.assignAndRegister.newAssignmentForm),
        link: '#',
        icon: <SvgForm />,
      },
    ],
  }

  const unloadDistributorTGDBasic: DistributorState = {
    title: t(orderHomeTranslation.distributors.unload.title),
    subtitle: t(orderHomeTranslation.distributors.unload.subtitle),
    icon: <SvgDistributorDownload />,
    childs: [
      {
        title: t(orderHomeTranslation.distributors.unload.newUnloadExcel),
        link: '#',
        icon: <SvgExcel />,
      },
      {
        title: t(orderHomeTranslation.distributors.unload.newUnloadForm),
        link: '#',
        icon: <SvgForm />,
      },
    ],
  }

  // TT

  const rechargeDistributorTT: DistributorState = {
    title: t(orderHomeTranslation.distributors.recharge.title),
    subtitle: t(orderHomeTranslation.distributors.recharge.subtitle),
    icon: <SvgDistributorRecharge />,
    childs: [
      {
        title: t(orderHomeTranslation.distributors.recharge.newCardExcel),
        link: navigationRoutes.ttRechargeExcel,
        icon: <SvgExcel />,
      },
      {
        title: t(orderHomeTranslation.distributors.recharge.newCardForm),
        link: navigationRoutes.ttRechargeSelectMultipleEmployee,
        icon: <SvgForm />,
      },
    ],
  }

  const cardDistributorTT: DistributorState = {
    title: t(orderHomeTranslation.distributors.newCard.title),
    subtitle: t(orderHomeTranslation.distributors.newCard.subtitle),
    icon: <SvgDistributorCard />,
    childs: [
      {
        title: t(orderHomeTranslation.distributors.newCard.newCardExcel),
        link: navigationRoutes.ttNewOrderExcel,
        icon: <SvgExcel />,
      },
      {
        title: t(orderHomeTranslation.distributors.newCard.newCardForm),
        link: navigationRoutes.ttOrderForm,
        icon: <SvgForm />,
      },
      {
        title: t(orderHomeTranslation.distributors.newCard.newDuplicated),
        link: navigationRoutes.ttOrderSelectMultipleEmployee,
        icon: <SvgForm />,
      },
    ],
  }

  const unloadDistributorTT: DistributorState = {
    title: t(orderHomeTranslation.distributors.unload.title),
    subtitle: t(orderHomeTranslation.distributors.unload.subtitle),
    icon: <SvgDistributorDownload />,
    childs: [
      {
        title: t(orderHomeTranslation.distributors.unload.newUnloadExcel),
        link: navigationRoutes.ttUnloadExcel,
        icon: <SvgExcel />,
      },
      {
        title: t(orderHomeTranslation.distributors.unload.newUnloadForm),
        link: navigationRoutes.ttUnloadSelectMultipleEmployee,
        icon: <SvgForm />,
      },
    ],
  }

  const getAllDistributorsTR = (): DistributorState[] => {
    const distributors: DistributorState[] = [
      rechargeDistributorTR,
      cardDistributorTR,
      unloadDistributorTR,
      repeatOrderDistributor,
    ]
    return distributors
  }

  const getAllDistributorsTR1 = (): DistributorState[] => {
    const distributors: DistributorState[] = [rechargeDistributorTR, cardDistributorTR]
    return distributors
  }

  const getAllDistributorsTR2 = (): DistributorState[] => {
    const distributors: DistributorState[] = [unloadDistributorTR, repeatOrderDistributor]
    return distributors
  }

  const getCardAndRechargeDistributorTR = (): DistributorState[] => {
    const distributors: DistributorState[] = [
      rechargeDistributorTR,
      cardDistributorTR,
      repeatOrderDistributor,
    ]
    return distributors
  }

  const getTGDPlusDistributor = (): DistributorState[] => {
    const distributors: DistributorState[] = [
      kindergartenPlusDistributor,
      repeatOrderDistributor,
    ]
    return distributors
  }

  const getAllDistributorsTGDBasic = (): DistributorState[] => {
    const distributors: DistributorState[] = [
      assignAndRegisterDistributorTGDBasic,
      unloadDistributorTGDBasic,
    ]
    return distributors
  }

  const getAllDistributorsTT = (): DistributorState[] => {
    const distributors: DistributorState[] = [
      rechargeDistributorTT,
      cardDistributorTT,
      unloadDistributorTT,
    ]
    return distributors
  }

  const getAllDistributorsTT1 = (): DistributorState[] => {
    const distributors: DistributorState[] = [rechargeDistributorTT, cardDistributorTT]
    return distributors
  }

  const getAllDistributorsTT2 = (): DistributorState[] => {
    const distributors: DistributorState[] = [unloadDistributorTT, repeatOrderDistributor]
    return distributors
  }

  const getCardAndRechargeDistributorTT = (): DistributorState[] => {
    const distributors: DistributorState[] = [
      rechargeDistributorTT,
      cardDistributorTT,
      repeatOrderDistributor,
    ]
    return distributors
  }

  return {
    getAllDistributorsTR,
    getAllDistributorsTR1,
    getAllDistributorsTR2,
    getCardAndRechargeDistributorTR,
    getTGDPlusDistributor,
    getAllDistributorsTGDBasic,
    getAllDistributorsTT,
    getAllDistributorsTT1,
    getAllDistributorsTT2,
    getCardAndRechargeDistributorTT,
  }
}
