import { IncompleteOrderClientHome } from 'src/presentation/components/client/home/molecules/IncompleteOrderClientHome'
import { RepeatLastOrdersClientHome } from 'src/presentation/components/client/home/molecules/RepeatLastOrdersClientHome'
import { Grid } from 'src/presentation/components/Edenred/layout'

interface Props {
  productCode?: number
}

export const OrderHomeContainer = ({ productCode }: Props): JSX.Element => {
  return (
    <Grid columns={2} columnGap="2rem" rowGap="2rem" className="home-tables-grid pt-1">
      <IncompleteOrderClientHome productCode={productCode} />
      <RepeatLastOrdersClientHome productCode={productCode} />
    </Grid>
  )
}
