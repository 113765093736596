import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { currency, emptyValues } from '../../domain/enum'
import type { CardOrderModel } from '../../domain/models'

export const getUndefinedDashString = (value: string | undefined | null): string => {
  if (value && value !== 'null') {
    return value.toString()
  }
  return emptyValues.dash
}

export const getUndefinedEmptyString = (value: string | undefined | null): string => {
  if (value && value !== 'null') {
    return value.toString()
  }
  return emptyValues.empty
}

export const getTotalAmountRecharge = (order: CardOrderModel | TTOrderModel): string => {
  if (order.cardData.balance === emptyValues.dash) {
    return emptyValues.dash
  } else {
    if (order.cardData.balance && order.cardData.initialAmount) {
      let result =
        +(order.cardData.balance.toString().includes(currency.euro)
          ? order.cardData.balance.slice(0, -1)
          : order.cardData.balance) + order.cardData.initialAmount
      if (result % 1 > 0) result = +result.toFixed(2)
      return result + currency.euro
    }
    return emptyValues.dash
  }
}

export const getTotalAmountUnload = (order: CardOrderModel | TTOrderModel): string => {
  if (order.cardData.balance === emptyValues.dash) {
    return emptyValues.dash
  }
  if (!order.cardData.balance || !order.cardData.initialAmount) {
    return emptyValues.dash
  }
  return (
    Math.max(
      parseInt(order.cardData.balance) - order.cardData.initialAmount,
      0
    ).toString() + currency.euro
  )
}

export const addCurrency = (amount: number | string | null | undefined): string => {
  if (amount) {
    if (amount.toString().includes('-')) return emptyValues.dash
    return amount.toString() + currency.euro
  }
  return emptyValues.dash
}

export const addCurrencyPPS = (
  amount: number | string | null | undefined,
  pps: boolean
): string => {
  if (!pps) {
    return emptyValues.dash
  }
  if (amount) {
    return amount.toString() + currency.euro
  }
  return emptyValues.zero + currency.euro
}

export const getAcronym = (name: string | undefined): string => {
  if (name) {
    const words = name.split(' ').slice(0, 2)
    const acr = words.map((word: string): string => word[0])
    return acr.join('').toUpperCase()
  }
  return ''
}

// Converts any object with properties like grid filters to url query params.
// Example: { name: 'John', age: 20 } => name=John&age=20
export const GetUrSearchParams = (object: any): string => {
  return new URLSearchParams(object).toString()
}

export const addComma = (text: string): string => {
  if (!text) {
    return emptyValues.empty
  }
  if (text.replaceAll(/\s/g, '') !== emptyValues.empty) {
    return text.trim() + ', '
  }
  return emptyValues.empty
}
