import { OreDistributor } from '@edenredespana/oreneta'
import { Fragment, useState } from 'react'
import { AlignPosition } from '../../../../../domain/enum'
import type {
  DistributorChildsState,
  DistributorState,
} from '../../../../../domain/models/cardOrder/distributors/DistributorModel'
import { DistributorLink } from '../atoms'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'

interface Props {
  position: number
  distributor: DistributorState
}

export const Distributor = ({ position, distributor }: Props): JSX.Element => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()

  return (
    <div
      className={position === AlignPosition.horizontal ? '' : 'distributor-left'}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      style={{ paddingBottom: '20px' }}>
      {distributor.childs.length > 0 ? (
        <OreDistributor
          title={distributor.title}
          subtitle={distributor.subtitle}
          icon={distributor.icon}
          open={open}>
          {distributor.childs.map((child: DistributorChildsState, index: number) => {
            return (
              <DistributorLink
                key={index}
                title={child.title}
                link={child.link}
                icon={child.icon}
              />
            )
          })}
        </OreDistributor>
      ) : (
        <OreDistributor
          title={distributor.title}
          subtitle={distributor.subtitle}
          icon={distributor.icon}
          handleClick={() => navigate(navigationRoutes.myOrdersHistory)}
          open={open}
        />
      )}
    </div>
  )
}
