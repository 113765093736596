import {
  ChangeEvent,
  MouseEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreDivider,
  OreErrorText,
  OreFormGroup,
  OreHeading,
  OreInput,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreRadioButton,
  OreText,
  SvgBin,
  SvgPlus,
  OreStack,
  OreCardContainer,
  OreCardWrapper,
  SvgExternalHealth,
  OreCardTitle,
  OreSwitch,
  OreIcon,
} from '@edenredespana/oreneta'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { forms } from 'src/domain/translations'
import {
  ExternalHealthForm,
  useConfigureExternalHealthInsuranceController,
  ConfigureModalActions,
  ExternalHealthUserAlert,
  EmployeeContractMessage,
  PolicyEmployeesAffected,
  EmployeeContractAlert,
  ExternalHealthUserDeleteAlert,
} from 'src/Flex/Products/ui/product-configuration'
import { currency, edenredProducts } from 'src/domain/enum'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { decimalValueInput } from 'src/core/helpers'
import { Divider } from 'src/presentation/layout'
import { v4 as uuidv4 } from 'uuid'

export const ConfigureExternalHealthInsuranceModalForm =
  forwardRef<ConfigureModalActions>((_, ref) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const [isWarning, setIsWarning] = useState(false)
    const [forceDelete, setForceDelete] = useState(false)
    const {
      form: {
        control,
        handleSubmit,
        register,
        formState,
        clearErrors,
        getFieldState,
        setValue,
        getValues,
        watch,
        trigger,
      },
      load,
      deletePolicy,
      isGlobal,
      policyEmployeesAffected,
      setPolicyEmployeesAffected,
      confirmPolicy,
      validatePolicy,
      confirmEmployeeAffected,
      validateEmployeeAffected,
      employeesAffected,
      setEmployeesAffected,
      getProduct,
      validateDeletePolicy,
      collective,
    } = useConfigureExternalHealthInsuranceController()
    const { errors } = handleErrors(formState, getFieldState)
    const activeWatch = watch('active')
    const { fields, append, remove } = useFieldArray({
      control: control,
      name: 'policies',
    })

    const close = (): void => {
      resetAllPolicyState()
      setEmployeesAffected(undefined)
      setOpen(false)
    }

    const confirm = (event: MouseEvent): void => {
      event.preventDefault()

      handleSubmit(async (data: ExternalHealthForm) => {
        const result = await confirmEmployeeAffected(edenredProducts.saludExterno, data)

        if (result) {
          resetAllPolicyState()
          setEmployeesAffected(undefined)
          setOpen(false)
        }
      })()
    }

    const validate = async (event: MouseEvent): Promise<void> => {
      event.preventDefault()
      await handleSubmit(async (data: ExternalHealthForm) => {
        const result = await validateEmployeeAffected(edenredProducts.saludExterno, data)
      })()
    }

    const savePolicyChanges = (event: MouseEvent, index: number): void => {
      event.preventDefault()
      handleSubmit(async (data: ExternalHealthForm) => {
        const result = await confirmPolicy({
          company: data.policies[index].company,
          id: data.policies[index].id === '' ? uuidv4() : data.policies[index].id,
          monthlyLimit: data.policies[index].monthlyPrice,
          policyName: data.policies[index].policyName,
          arePhoneRequired: data.policies[index].arePhoneRequired === 'yes',
          areAddressRequired: data.policies[index].areAddressRequired === 'yes',
        })
        if (result) {
          setValue(`policies.${index}.isWarningMonthlyPrice`, false)
          setValue(`policies.${index}.isWarningPolicyName`, false)
          setValue(`policies.${index}.isWarningCompany`, false)
          setValue(`policies.${index}.isWarningArePhoneRequired`, false)
          setValue(`policies.${index}.isWarningAreAddressRequired`, false)
          setValue(`policies.${index}.updated`, true)
          setIsWarning(false)
        }
      })()
    }

    const validatePolicyChanges = (event: MouseEvent, index: number): void => {
      event.preventDefault()
      handleSubmit(async (data: ExternalHealthForm) => {
        await validatePolicy({
          company: data.policies[index].company,
          id: data.policies[index].id,
          monthlyLimit: data.policies[index].monthlyPrice,
          policyName: data.policies[index].policyName,
          arePhoneRequired: data.policies[index].arePhoneRequired === 'yes',
          areAddressRequired: data.policies[index].areAddressRequired === 'yes',
        })
      })()
    }
    const forceDeletePolicy = (event: MouseEvent, index: number): void => {
      event.preventDefault()

      handleSubmit(async (data: ExternalHealthForm) => {
        await validateDeletePolicy(data.policies[index].id)
        setValue(`policies.${index}.forceDelete`, true)
        setForceDelete(!forceDelete)
      })()
    }

    const deletePolicyToApi = (event: MouseEvent, index: number): void => {
      event.preventDefault()
      handleSubmit(async (data: ExternalHealthForm) => {
        const result = await deletePolicy(data.policies[index].id)
        if (result) {
          remove(index)
        }
      })()
    }

    const resetPolicyState = (index: number): void => {
      const cloneArray: PolicyEmployeesAffected[] = [...policyEmployeesAffected]
      const policyId = getValues().policies[index].id
      cloneArray.map(policy => {
        if (policy.policyId === policyId) {
          policy.employeesAffected = undefined
        }
      })
      setPolicyEmployeesAffected(cloneArray)
    }

    const resetAllPolicyState = (): void => {
      const cloneArray: PolicyEmployeesAffected[] = [...policyEmployeesAffected]
      cloneArray.map(policy => {
        policy.employeesAffected = undefined
      })
      setPolicyEmployeesAffected(cloneArray)
    }

    useImperativeHandle<ConfigureModalActions, ConfigureModalActions>(ref, () => ({
      open: (): void => {
        load().then(() => {
          setOpen(true)
        })
      },
      close,
    }))
    useEffect(() => {
      setEmployeesAffected(undefined)
    }, [activeWatch])

    return (
      <OreModal open={isOpen}>
        <OreModalBox
          size="large"
          handleOnClose={() => {
            resetAllPolicyState()
            setEmployeesAffected(undefined)
            setOpen(false)
          }}>
          <form>
            <OreModalBoxBody noGap>
              <OreCardContainer hasShadow={false}>
                <OreCardTitle
                  icon={
                    <OreIcon
                      size="large"
                      icon={<SvgExternalHealth />}
                      tone="flex-products"
                    />
                  }
                  title={t(flexProductsTranslations.configure.healthExternal.title)}
                />
                <Divider marginTop="1rem" marginBottom="2rem" />
                <OreStack placeContent="stretch" space="large">
                  <OreStack space="large">
                    <OreStack placeItems="start" space="medium">
                      <OreText tone="neutral-600">
                        {t(flexProductsTranslations.configure.common.collectivesWarning)}
                        {fields.length === 1
                          ? ` ` +
                            t(
                              flexProductsTranslations.configure.healthExternal
                                .no_remove_last,
                            )
                          : null}
                      </OreText>
                      {fields.map((field, index) => {
                        const arePhoneRequiredErrors = errors(
                          `policies.${index}.arePhoneRequired`,
                        )
                        const areAddressRequiredErrors = errors(
                          `policies.${index}.areAddressRequired`,
                        )
                        const { onChange, ...rest } = register(
                          `policies.${index}.monthlyPrice`,
                        )

                        const handleMonthlyLimitChange = (
                          event: ChangeEvent<HTMLInputElement>,
                        ): void => {
                          let value: string | undefined = event.target.value
                          if (value === '') {
                            value = undefined
                          }
                          event.preventDefault()

                          if (Number(event.target.value) % 1 !== 0) {
                            setValue(
                              `policies.${index}.monthlyPrice`,
                              Number(decimalValueInput(event.target.value)),
                            )
                          }
                          formState.defaultValues &&
                          formState.defaultValues.policies &&
                          (Number(decimalValueInput(event.target.value)) !== 0
                            ? formState.defaultValues.policies[index]?.monthlyPrice ===
                              Number(decimalValueInput(event.target.value))
                            : formState.defaultValues.policies[index]?.monthlyPrice ===
                              value)
                            ? setValue(`policies.${index}.isWarningMonthlyPrice`, false)
                            : setValue(`policies.${index}.isWarningMonthlyPrice`, true)
                          setValue(`policies.${index}.forceDelete`, false)
                          setIsWarning(!isWarning)
                          onChange(event)
                          resetPolicyState(index)
                        }

                        const handlePolicyNameChange = (
                          event: ChangeEvent<HTMLInputElement>,
                        ): void => {
                          let value: string | undefined = event.target.value
                          if (value === '') {
                            value = undefined
                          }
                          event.preventDefault()
                          formState.defaultValues &&
                          formState.defaultValues.policies &&
                          formState.defaultValues.policies[index]?.policyName === value
                            ? setValue(`policies.${index}.isWarningPolicyName`, false)
                            : setValue(`policies.${index}.isWarningPolicyName`, true)
                          setValue(`policies.${index}.forceDelete`, false)
                          setIsWarning(!isWarning)
                          onChange(event)
                          resetPolicyState(index)
                        }

                        const handleCompanyNameChange = (
                          event: ChangeEvent<HTMLInputElement>,
                        ): void => {
                          let value: string | undefined = event.target.value
                          if (value === '') {
                            value = undefined
                          }
                          event.preventDefault()
                          formState.defaultValues &&
                          formState.defaultValues.policies &&
                          formState.defaultValues.policies[index]?.company === value
                            ? setValue(`policies.${index}.isWarningCompany`, false)
                            : setValue(`policies.${index}.isWarningCompany`, true)
                          setValue(`policies.${index}.forceDelete`, false)
                          setIsWarning(!isWarning)
                          onChange(event)
                          resetPolicyState(index)
                        }

                        const handleArePhoneRequiredChange = (
                          event: ChangeEvent<HTMLInputElement>,
                        ): void => {
                          let value: string | undefined = event.target.value
                          if (value === 'no' && field.isNew) {
                            value = undefined
                          }
                          formState.defaultValues &&
                          formState.defaultValues.policies &&
                          formState.defaultValues.policies[index]?.arePhoneRequired ===
                            value
                            ? setValue(
                                `policies.${index}.isWarningArePhoneRequired`,
                                false,
                              )
                            : setValue(
                                `policies.${index}.isWarningArePhoneRequired`,
                                true,
                              )
                          setValue(`policies.${index}.forceDelete`, false)
                          setIsWarning(!isWarning)
                          onChange(event)
                          resetPolicyState(index)
                        }

                        const handleAreAddressRequiredChange = (
                          event: ChangeEvent<HTMLInputElement>,
                        ): void => {
                          let value: string | undefined = event.target.value
                          if (value === 'no' && field.isNew) {
                            value = undefined
                          }
                          formState.defaultValues &&
                          formState.defaultValues.policies &&
                          formState.defaultValues.policies[index]?.areAddressRequired ===
                            value
                            ? setValue(
                                `policies.${index}.isWarningAreAddressRequired`,
                                false,
                              )
                            : setValue(
                                `policies.${index}.isWarningAreAddressRequired`,
                                true,
                              )
                          setValue(`policies.${index}.forceDelete`, false)
                          setIsWarning(!isWarning)
                          onChange(event)
                          resetPolicyState(index)
                        }

                        return (
                          <OreCardContainer
                            key={field.id}
                            hasShadow={false}
                            tone="neutral-100">
                            <OreCardWrapper>
                              <OreStack space="medium">
                                <OreHeading size="headline-md">
                                  {t(
                                    flexProductsTranslations.configure.healthExternal.form
                                      .policies.label,
                                  )}
                                </OreHeading>
                                <div
                                  style={{
                                    border: 'solid 1px var(--color-neutral-200)',
                                    borderRadius: 'var(--radius-small)',
                                  }}>
                                  {field &&
                                  field.contractStateInfo &&
                                  (field.contractStateInfo.isInProgress > 0 ||
                                    field.contractStateInfo.isPendingSignature +
                                      field.contractStateInfo.isInProcess >
                                      0) ? (
                                    <EmployeeContractMessage
                                      activeContracts={
                                        field.contractStateInfo.isInProgress
                                      }
                                      pendingContracts={
                                        field.contractStateInfo.isPendingSignature +
                                        field.contractStateInfo.isInProcess
                                      }
                                      isDefault={false}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <input
                                  type="hidden"
                                  {...register(`policies.${index}.id`)}
                                />
                                <OreInput
                                  {...register(`policies.${index}.company`)}
                                  {...errors(`policies.${index}.company`)}
                                  label={t(
                                    flexProductsTranslations.configure.healthExternal.form
                                      .company.label,
                                  )}
                                  onChange={handleCompanyNameChange}
                                  required
                                />
                                <OreInput
                                  {...register(`policies.${index}.policyName`)}
                                  {...errors(`policies.${index}.policyName`)}
                                  label={t(
                                    flexProductsTranslations.configure.healthExternal.form
                                      .policyName.label,
                                  )}
                                  onChange={handlePolicyNameChange}
                                  required
                                />
                                <OreInput
                                  {...rest}
                                  {...errors(`policies.${index}.monthlyPrice`)}
                                  type="number"
                                  startAdornment={currency.euro}
                                  required
                                  legend={t(
                                    flexProductsTranslations.configure.healthExternal.form
                                      .monthlyImport.legend,
                                  )}
                                  label={t(
                                    flexProductsTranslations.configure.healthExternal.form
                                      .monthlyImport.label,
                                  )}
                                  onChange={handleMonthlyLimitChange}
                                />
                                <OreStack space="medium">
                                  <OreFormGroup
                                    {...arePhoneRequiredErrors}
                                    direction="row"
                                    label={t(
                                      flexProductsTranslations.configure.healthExternal
                                        .form.phone.label,
                                    )}
                                    required>
                                    <OreRadioButton
                                      {...register(`policies.${index}.arePhoneRequired`)}
                                      label={t(forms.labels.yes)}
                                      value="yes"
                                      hasError={arePhoneRequiredErrors.hasError}
                                      onChange={handleArePhoneRequiredChange}
                                    />
                                    <OreRadioButton
                                      {...register(`policies.${index}.arePhoneRequired`)}
                                      label={t(forms.labels.no)}
                                      value="no"
                                      hasError={arePhoneRequiredErrors.hasError}
                                      onChange={handleArePhoneRequiredChange}
                                    />
                                  </OreFormGroup>
                                  <OreFormGroup
                                    {...areAddressRequiredErrors}
                                    direction="row"
                                    label={t(
                                      flexProductsTranslations.configure.healthExternal
                                        .form.address.label,
                                    )}
                                    required>
                                    <OreRadioButton
                                      {...register(
                                        `policies.${index}.areAddressRequired`,
                                      )}
                                      label={t(forms.labels.yes)}
                                      value="yes"
                                      hasError={areAddressRequiredErrors.hasError}
                                      onChange={handleAreAddressRequiredChange}
                                    />
                                    <OreRadioButton
                                      {...register(
                                        `policies.${index}.areAddressRequired`,
                                      )}
                                      label={t(forms.labels.no)}
                                      value="no"
                                      hasError={areAddressRequiredErrors.hasError}
                                      onChange={handleAreAddressRequiredChange}
                                    />
                                  </OreFormGroup>
                                </OreStack>
                                {!getValues().policies[index].forceDelete ? (
                                  <ExternalHealthUserAlert
                                    field={field}
                                    formValues={getValues()}
                                    index={index}
                                    savePolicyChanges={savePolicyChanges}
                                    validatePolicyChanges={validatePolicyChanges}
                                    policyEmployeesAffected={policyEmployeesAffected}
                                  />
                                ) : null}
                                <ExternalHealthUserDeleteAlert
                                  field={field}
                                  formValues={getValues()}
                                  index={index}
                                  deletePolicyChanges={deletePolicyToApi}
                                  validateDeletePolicyChanges={forceDeletePolicy}
                                  policyEmployeesAffected={policyEmployeesAffected}
                                  collectiveId={collective?.id}
                                />

                                {fields.length > 1 && field.isNew ? (
                                  <>
                                    <OreDivider />
                                    <OreButton
                                      style={{ placeSelf: 'start' }}
                                      icon={<SvgBin />}
                                      category="tertiary"
                                      onClick={event => {
                                        if (field.updated) {
                                          deletePolicyToApi(event, index)
                                        } else {
                                          event.preventDefault()
                                          remove(index)
                                        }
                                      }}>
                                      {t(
                                        flexProductsTranslations.configure.healthExternal
                                          .delete_policy,
                                      )}
                                    </OreButton>
                                  </>
                                ) : null}
                                {field.isNew &&
                                (getValues().policies[index]
                                  .isWarningAreAddressRequired ||
                                  getValues().policies[index].isWarningArePhoneRequired ||
                                  getValues().policies[index].isWarningCompany ||
                                  getValues().policies[index].isWarningPolicyName ||
                                  getValues().policies[index].isWarningMonthlyPrice) ? (
                                  <>
                                    <OreStack placeContent="end">
                                      <OreButton
                                        type="submit"
                                        onClick={event => savePolicyChanges(event, index)}
                                        size="small"
                                        category="secondary">
                                        {t(
                                          flexProductsTranslations.configure
                                            .healthExternal.save_changes,
                                        )}
                                      </OreButton>
                                    </OreStack>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </OreStack>
                            </OreCardWrapper>
                          </OreCardContainer>
                        )
                      })}
                      {formState.errors.policies ? (
                        <OreErrorText>{formState.errors.policies.message}</OreErrorText>
                      ) : null}
                      <OreButton
                        icon={<SvgPlus />}
                        category="primary"
                        size="small"
                        onClick={() => {
                          append({
                            id: uuidv4(),
                            policyName: '',
                            company: '',
                            monthlyPrice: undefined,
                            isNew: true,
                            forceDelete: false,
                            arePhoneRequired: 'no',
                            areAddressRequired: 'no',
                          })
                          clearErrors('policies')
                        }}>
                        {t(
                          flexProductsTranslations.configure.healthExternal.form.actions
                            .add,
                        )}
                      </OreButton>
                    </OreStack>
                    {isGlobal && (
                      <div className="pt-1">
                        <OreStack>
                          <OreHeading size="headline-md">
                            {t(
                              flexProductsTranslations.configure.ticketRestaurant.form
                                .activate.title,
                            )}
                          </OreHeading>
                          <OreText>
                            {t(
                              flexProductsTranslations.configure.ticketRestaurant.form
                                .activate.subtitle,
                            )}
                          </OreText>
                          <OreSwitch
                            {...register('active')}
                            label={t(
                              flexProductsTranslations.configure.ticketRestaurant.form
                                .activate.activate,
                            )}
                            secondLabel={t(
                              flexProductsTranslations.configure.ticketRestaurant.form
                                .activate.deactivate,
                            )}
                          />
                        </OreStack>
                      </div>
                    )}
                    <OreText>{t(forms.errors.fieldsRequired)}</OreText>
                    {employeesAffected !== undefined && employeesAffected > 0 ? (
                      <EmployeeContractAlert
                        activeChange={true}
                        deleteChange={false}
                        numberOfContracts={employeesAffected}
                        productType={edenredProducts.saludExterno}
                        product={getProduct()}
                        collectiveId={collective?.id}
                      />
                    ) : null}
                    {employeesAffected !== undefined && employeesAffected === 0 ? (
                      <EmployeeContractAlert
                        activeChange={true}
                        deleteChange={false}
                        numberOfContracts={employeesAffected}
                        productType={edenredProducts.saludExterno}
                        product={getProduct()}
                        collectiveId={collective?.id}
                      />
                    ) : null}
                  </OreStack>
                </OreStack>
              </OreCardContainer>
            </OreModalBoxBody>
            <OreModalBoxFooter>
              <OreButton onClick={close} size="small" category="secondary">
                {t(forms.buttons.cancel)}
              </OreButton>
              {employeesAffected !== undefined ? (
                <OreButton
                  type="submit"
                  onClick={confirm}
                  size="small"
                  category={'danger'}>
                  {t(flexProductsTranslations.configure.common.submit)}
                </OreButton>
              ) : (
                <OreButton
                  type="submit"
                  onClick={validate}
                  size="small"
                  category={'primary'}>
                  {t(flexProductsTranslations.configure.common.submit)}
                </OreButton>
              )}
            </OreModalBoxFooter>
          </form>
        </OreModalBox>
      </OreModal>
    )
  })
