import { Grid, GridItem } from 'src/presentation/components/Edenred/layout'
import { IncompleteOrderClientHome } from '../molecules/IncompleteOrderClientHome'
import { RepeatLastOrdersClientHome } from '../molecules/RepeatLastOrdersClientHome'
import { InvoicesOrderClientHome } from '../molecules/InvoicesOrderClientHome'
import { RepeatFavoriteOrdersClientHome } from '../molecules/RepeatFavoriteOrdersClientHome'

export const ClientHomeFrequentActionsContainer = (): JSX.Element => {
  return (
    <Grid columns={2} columnGap="2rem" rowGap="2rem" className="home-tables-grid">
      <GridItem>
        <RepeatLastOrdersClientHome />
      </GridItem>
      <GridItem>
        <InvoicesOrderClientHome />
      </GridItem>
      <GridItem>
        {/* TODO: Eliminar clase cuando se vuelva a utilizar reminder-date*/}
        <div className="incomplete-order-home--wrapper">
          <IncompleteOrderClientHome />
        </div>
      </GridItem>
      <GridItem>
        <RepeatFavoriteOrdersClientHome />
      </GridItem>
    </Grid>
  )
}
