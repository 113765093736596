import { SvgClose, SvgInfo } from '@edenredespana/oreneta'
import { t } from 'i18next'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import type {
  CaptionModel,
  CellModel,
  HeaderModel,
  RowModel,
  SelectorModel,
} from '../../../../../domain/customComponents/table/TableModel'
import type { PopupButtonModel } from '../../../../../domain/customComponents/Popup'
import type { TableEmptyModel } from '../../../../../domain/customComponents/table'
import { edenredProducts, orderType } from '../../../../../domain/enum'
import type { CardOrderModel } from '../../../../../domain/models'
import {
  forms,
  recharge,
  rechargesConfigurationTranslations,
} from '../../../../../domain/translations'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useUser } from '../../../../context/user/UserProvider'
import { useModalController } from '../../../Edenred'
import {
  ErrorValidRechargeValue,
  MessageValidRechargeValue,
} from 'src/core/services/cardOrder'
import { EmployeesWithLastCardNew } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'

export interface ConfigurationState {
  header: HeaderModel
  rows: RowModel[]
  tableEmpty: TableEmptyModel
  selector: SelectorModel
  caption: CaptionModel
  setRows: Dispatch<SetStateAction<RowModel[]>>
  validateTable: boolean
  ValidateTableFields: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  setShowPopup: Dispatch<SetStateAction<boolean>>
  popupShow: boolean
  popupTitle: string
  popupDescription: string
  popupButtons: PopupButtonModel[]
  navigate: NavigateFunction
  clearCardOrderRecharges: () => void
}
export const useRechargesConfigurationController = (): ConfigurationState => {
  const {
    getCardOrderRecharges,
    setCardOrderRecharges,
    clearCardOrderRecharges,
    addOrders,
    clearOrders,
    orders,
    clearAll,
  } = useCardOrder()
  const navigate = useNavigate()
  const [recharges, setRecharges] = useState<EmployeesWithLastCardNew[]>(
    getCardOrderRecharges()
  )
  const [rows, setRows] = useState<RowModel[]>([])
  const [selector, setSelector] = useState<SelectorModel>({
    buttonTitle: '',
    description: '',
    title: '',
    selectors: [],
  })
  const [validateTable, setValidateTable] = useState<boolean>(false)

  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const { company } = useUser()

  const caption: CaptionModel = {
    title: t(rechargesConfigurationTranslations.table.caption.title),
    description: t(rechargesConfigurationTranslations.table.caption.description),
  }
  const header: HeaderModel = {
    headerLabel: [
      { label: t(rechargesConfigurationTranslations.table.header.name) },
      {
        label: t(rechargesConfigurationTranslations.table.header.amount),
        tooltip: t(recharge.tableHeader.balanceTooltip),
        tooltipIcon: <SvgInfo />,
      },
      {
        label: t(rechargesConfigurationTranslations.table.header.recharge),
        tooltip: t(rechargesConfigurationTranslations.table.header.rechargeTooltip),
        tooltipIcon: <SvgInfo />,
      },
      { label: t(rechargesConfigurationTranslations.table.header.costs) },
    ],
  }

  const tableEmpty: TableEmptyModel = {
    colSpan: 7,
    firstText: t(rechargesConfigurationTranslations.tableEmpty.first),
    linkText: t(rechargesConfigurationTranslations.tableEmpty.link),
    linkUrl: navigationRoutes.rechargeCard,
    endText: t(rechargesConfigurationTranslations.tableEmpty.end),
  }

  const GetRows = () => {
    const rechargesRows: RowModel[] = []
    Seq(recharges).forEach((recharge: EmployeesWithLastCardNew) => {
      const duplicateRow: RowModel = {
        rowId: recharge.userId,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: recharge.userName,
                name: 'name',
                tone: 'neutral-900',
                size: 'text-sm',
                isBold: true,
              },
              { text: recharge.document, name: 'document', size: 'text-sm' },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: recharge.balance,
                name: 'balance',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'textBox',
            cellControll: {
              name: 'recharge',
              type: 'number',
              placeHolder: t(
                rechargesConfigurationTranslations.table.rows.recharge.placeHolder
              ),
              value: '',
              startAdornment: '€',
              errorValidate: ErrorValidRechargeValue,
              massageErrorValidate: MessageValidRechargeValue,
            },
          },
          {
            type: 'textBox',
            cellControll: {
              name: 'costs',
              type: 'text',
              placeHolder: t(rechargesConfigurationTranslations.table.rows.costs),
              value: recharge.costCenter ? recharge.costCenter : '',
            },
          },
        ],
      }
      rechargesRows.push(duplicateRow)
    })
    setRows(rechargesRows)
    setSelector({
      description: t(rechargesConfigurationTranslations.selector.description),
      title: t(rechargesConfigurationTranslations.selector.title),
      buttonTitle: t(rechargesConfigurationTranslations.selector.button),
      selectors: [
        {
          type: 'textBox',
          cellControll: {
            name: 'recharge',
            type: 'number',
            placeHolder: t(
              rechargesConfigurationTranslations.table.rows.recharge.placeHolder
            ),
            label: t(rechargesConfigurationTranslations.table.header.recharge),
            value: '',
            startAdornment: '€',
          },
        },
        {
          type: 'textBox',
          cellControll: {
            name: 'costs',
            type: 'text',
            placeHolder: t(rechargesConfigurationTranslations.table.rows.costs),
            label: t(rechargesConfigurationTranslations.table.header.costs),
            value: '',
          },
        },
      ],
    })
  }

  const getRechargesOnContext = () => {
    const rechargesOnContext: EmployeesWithLastCardNew[] = []
    Seq(recharges).forEach((recharge: EmployeesWithLastCardNew) => {
      if (rows.find((row: RowModel) => row.rowId === recharge.userId))
        rechargesOnContext.push(recharge)
    })
    setCardOrderRecharges(rechargesOnContext)
  }

  const ValidateTableFields = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    setValidateTable(true)
  }

  const GetErrors = (): boolean => {
    let hasError = false
    Seq(rows).forEach((row: RowModel) => {
      Seq(row.cells).forEach((cell: CellModel) => {
        if (cell.cellControll && cell.cellControll.hasError === true) {
          hasError = true
          return
        }
      })
    })
    return hasError
  }

  const addRechargesToCardOrder = () => {
    const cardOrders: CardOrderModel[] = []
    Seq(rows).forEach((row: RowModel, rowIndex: number) => {
      let name: string | undefined
      let document: string | undefined
      let amount: string | undefined
      let costs: string | undefined
      let companyId: number | undefined
      const recharge: EmployeesWithLastCardNew | undefined = recharges.find(
        recharge => recharge.userId === row.rowId
      )
      Seq(row.cells).forEach((cell: CellModel) => {
        if (
          cell.cellText &&
          cell.cellText.some(text => text.name === 'name' || text.name === 'document')
        ) {
          name = cell.cellText.find(text => text.name === 'name')?.text
          document = cell.cellText.find(text => text.name === 'document')?.text
        }

        if (cell.cellControll && cell.cellControll.name === 'recharge') {
          amount = cell.cellControll.value
        }

        if (cell.cellControll && cell.cellControll.name === 'costs') {
          costs = cell.cellControll.value
        }
      })

      if (company) companyId = company.code

      if (recharge) {
        const cardOrder: CardOrderModel = {
          orderId: new Date().getTime() + rowIndex,
          orderTypeId: orderType.Recharge,
          cardData: {
            productTypeId: edenredProducts.ticketRestaurant,
            cardTypeId: Number(recharge.cardTypeId),
            initialAmount: Number(amount),
            balance: recharge.balance ? recharge.balance.toString() : '',
          },
          employeeData: {
            name: name?.trim(),
            firstSurname: '',
            secondSurname: '',
            document: document?.trim(),
            companyId: companyId,
            employeeNumber: recharge.employeeNumber,
            costCenter: costs,
          },
        }
        cardOrders.push(cardOrder)
      }
    })
    const validatedOrders = validateRechargesOrders(cardOrders)
    clearOrders()
    clearCardOrderRecharges()
    addOrders(validatedOrders)
    navigate(navigationRoutes.rechargeHome)
  }

  const handleDiscardPopup = () => {
    clearAll()
    clearCardOrderRecharges()
    setShowPopup(false)
    navigate(navigationRoutes.rechargeHome)
  }

  const SetPopupButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => handleDiscardPopup(),
        size: 'large',
      },
    ]
  }

  const validateRechargesOrders = (cardOrders: CardOrderModel[]) => {
    if (orders.length > 0) {
      const cardOrderConfigContext: CardOrderModel[] = []
      Seq(cardOrders).forEach((cardOrder: CardOrderModel) => {
        Seq(orders).forEach((order: CardOrderModel) => {
          if (order.employeeData.document === cardOrder.employeeData.document)
            cardOrderConfigContext.push(cardOrder)
        })
        const newCardOrder: CardOrderModel | undefined = cardOrderConfigContext.find(
          order => order.employeeData.document === cardOrder.employeeData.document
        )
        if (!newCardOrder) cardOrderConfigContext.push(cardOrder)
      })

      Seq(orders).forEach((order: CardOrderModel) => {
        const cardOrderContext: CardOrderModel | undefined = cardOrderConfigContext.find(
          cardOrder => cardOrder.employeeData.document === order.employeeData.document
        )
        if (!cardOrderContext) cardOrderConfigContext.push(order)
      })
      return cardOrderConfigContext
    }
    return cardOrders
  }

  useEffect(() => {
    setTitlePopup(t(rechargesConfigurationTranslations.modal.title))
    setDescriptionPopup(t(rechargesConfigurationTranslations.modal.description))
    setButtonsPopup(SetPopupButtons())
  }, [])

  useEffect(() => {
    if (recharges.length > 0) GetRows()
  }, [recharges])

  useEffect(() => {
    getRechargesOnContext()
  }, [rows])

  useEffect(() => {
    if (validateTable) {
      if (!GetErrors()) addRechargesToCardOrder()
      setValidateTable(false)
    }
  }, [rows])

  return {
    header,
    rows,
    selector,
    caption,
    validateTable,
    setRows,
    ValidateTableFields,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    navigate,
    clearCardOrderRecharges,
    tableEmpty,
  }
}
