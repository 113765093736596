import {
  OreHeading,
  OreList,
  OreListItem,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import { flexEmployeesTranslations } from '../../translations'
import { useTranslation } from 'react-i18next'

export const ChangeCollectiveMessage = (props: {
  numberOfContracts: number
  downloadFile: () => Promise<void>
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <OreHeading align="left" size="headline-md">
        {t(flexEmployeesTranslations.changeCollective.title)}
      </OreHeading>
      <OreStack space="large" placeContent="stretch">
        <div className="ore-list">
          <OreList bullet>
            <OreListItem>
              <OreText>
                {t(flexEmployeesTranslations.changeCollective.line1)}
                <a onClick={props.downloadFile}>
                  {props.numberOfContracts +
                    t(flexEmployeesTranslations.changeCollective.line1_1)}
                </a>
                {t(flexEmployeesTranslations.changeCollective.line1_2)}
              </OreText>
            </OreListItem>
            <OreListItem>
              <OreText>{t(flexEmployeesTranslations.changeCollective.line2)}</OreText>
            </OreListItem>
            <OreListItem>
              <OreText>{t(flexEmployeesTranslations.changeCollective.line3)}</OreText>
            </OreListItem>
          </OreList>
        </div>
      </OreStack>
    </>
  )
}
