import { OreCheckbox, OreTableCell, OreTableRow, OreText } from '@edenredespana/oreneta'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import {
  addCurrency,
  getCardOrderStatusLabelById,
  getUndefinedDashString,
} from 'src/core/services'
import { EmployeesWithLastCard } from 'src/domain/models'
import { OreTooltipCustom } from 'src/presentation/components/Edenred/OreTooltipCustom'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

interface Props {
  employeeTT: EmployeesWithLastCard
  selectedEmployees: EmployeesWithLastCard[]
  setSelectedEmployees: Dispatch<SetStateAction<EmployeesWithLastCard[]>>
  onSelectEmployee(
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesWithLastCard
  ): void
  isSelected(employee: EmployeesWithLastCard): boolean
  isInContext: (employee: EmployeesWithLastCard) => boolean
}

export const TTUnloadSelectMultipleEmployeesRow = ({
  employeeTT,
  onSelectEmployee,
  isSelected,
  isInContext,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const getRowWithTooltip = (tooltip: string, enabled: boolean): JSX.Element => {
    return (
      <OreTooltipCustom text={tooltip} placement="left" separation="xsmall">
        {getRow(enabled)}
      </OreTooltipCustom>
    )
  }

  const getRow = (enabled: boolean): JSX.Element => {
    return (
      <OreTableRow valign="middle" hover={enabled}>
        <OreTableCell align="left" as="td" size="auto">
          <OreCheckbox
            name="check"
            onChange={event => onSelectEmployee(event, employeeTT)}
            role="checkbox"
            aria-checked={isSelected(employeeTT)}
            checked={isSelected(employeeTT)}
            disabled={
              isInContext(employeeTT) || !employeeTT.balance || employeeTT.balance === 0
            }
          />
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone={enabled ? 'neutral-800' : 'neutral-300'}>
            {employeeTT.userName}
          </OreText>
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {employeeTT.document}
          </OreText>
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {employeeTT.email}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {getUndefinedDashString(employeeTT.employeeNumber)}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {t(getCardOrderStatusLabelById(employeeTT.cardStatusId))}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {addCurrency(employeeTT.balance)}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {getUndefinedDashString(employeeTT.costCenter)}
          </OreText>
        </OreTableCell>
      </OreTableRow>
    )
  }

  if (isInContext(employeeTT))
    return getRowWithTooltip(
      t(TTOrderTranslation.selectMultipleEmployees.table.unloadTooltip),
      false
    )
  if (!employeeTT.balance || employeeTT.balance === 0)
    return getRowWithTooltip(
      t(TTOrderTranslation.selectMultipleEmployees.table.emptyBalanceTooltip),
      false
    )
  return getRow(true)
}
