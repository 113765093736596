import {
  OreCheckbox,
  OreHeading,
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTableSortLabel,
  OreText,
  OreTooltip,
} from '@edenredespana/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
// import { useTranslation } from 'react-i18next'
import { EmployeesWithLastCardNew } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { UnloadEmployeesCaption } from './UnloadEmployeesCaption'
import { UnloadEmployeesState } from '../hooks/useUnloadEmployees'
import { unloadTranslation } from 'src/domain/translations'
import { getCardOrderStatusLabelById } from 'src/core/services'
import { ChangeEvent, Fragment } from 'react'
import { OreTooltipCustom } from 'src/presentation/components/Edenred/OreTooltipCustom'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { useTranslation } from 'react-i18next'

interface Props {
  unloadEmpoyeesProps: UnloadEmployeesState
}
interface RowProps {
  index: number
  employee: EmployeesWithLastCardNew
  onSelectEmployee: (
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesWithLastCardNew
  ) => void
  isSelected: (employee: EmployeesWithLastCardNew) => boolean
  enabled: boolean
}

export const UnloadEmployeesTable = ({ unloadEmpoyeesProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    totalRows,
    header,
    employees,
    onPageChange,
    page,
    pageSize,
    employeesSelected,
    onSelectEmployee,
    isSelected,
    showSelected,
  } = unloadEmpoyeesProps

  const GetRow = ({
    index,
    employee,
    onSelectEmployee,
    isSelected,
    enabled,
  }: RowProps): JSX.Element => {
    return (
      <OreTableRow valign="middle" key={'rows' + index} hover={enabled}>
        <OreTableCell>
          <OreCheckbox
            name="check"
            onChange={event => onSelectEmployee(event, employee)}
            role="checkbox"
            aria-checked={isSelected(employee)}
            checked={isSelected(employee)}
            disabled={
              employee.balance === '0' ||
              employee.balance === '0€' ||
              employee.balance === '-'
            }
          />
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone={enabled ? 'neutral-800' : 'neutral-300'}>
            {employee.userName}
          </OreText>
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {employee.document}
          </OreText>
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {employee.email}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {employee.employeeNumber}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {t(getCardOrderStatusLabelById(employee.cardStatusId))}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {employee.balance}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText size="text-sm" tone={enabled ? 'neutral-500' : 'neutral-300'}>
            {employee.costCenter}
          </OreText>
        </OreTableCell>
      </OreTableRow>
    )
  }

  return (
    <OreTableContainer>
      <div className="bg-white">
        <Grid items="center" columnGap="1rem" className="px-1 pt-1">
          <OreHeading size="headline-md">{t(unloadTranslation.captionTitle)}</OreHeading>
          <OreText tone="neutral-500" size="text-sm">
            {totalRows + ' ' + t(unloadTranslation.rowsTotalDescription)}
          </OreText>
        </Grid>
        <UnloadEmployeesCaption unloadEmpoyeesProps={unloadEmpoyeesProps} />
      </div>
      <OreTable>
        <OreTableHead>
          <OreTableRow hover={false}>
            {header.headerElement && (
              <OreTableCell as="th">{header.headerElement}</OreTableCell>
            )}
            {header.headerLabel.map((header, index) =>
              !header.isSortable ? (
                <OreTableCell as="th" key={'lbl' + index}>
                  {header.label}&nbsp;
                  {header.tooltip && (
                    <OreTooltip placement="top" separation="small" text={header.tooltip}>
                      <span>{header.tooltipIcon}</span>
                    </OreTooltip>
                  )}
                </OreTableCell>
              ) : (
                <OreTableCell as="th" key={'lbl' + index}>
                  {showSelected ? (
                    <>
                      {header.label}&nbsp;
                      {header.tooltip && (
                        <Fragment key={'tooltip_icon_' + index}>
                          {' '}
                          <OreTooltip
                            placement="top"
                            separation="small"
                            text={header.tooltip}>
                            <span>{header.tooltipIcon}</span>
                          </OreTooltip>
                        </Fragment>
                      )}
                    </>
                  ) : (
                    <OreTableSortLabel
                      active
                      direction={header.sortDirection}
                      onClick={header.onClick}>
                      {header.label}&nbsp;
                      {header.tooltip && (
                        <Fragment key={'tooltip_icon_' + index}>
                          {' '}
                          <OreTooltip
                            placement="top"
                            separation="small"
                            text={header.tooltip}>
                            <span>{header.tooltipIcon}</span>
                          </OreTooltip>
                        </Fragment>
                      )}
                    </OreTableSortLabel>
                  )}
                </OreTableCell>
              )
            )}
          </OreTableRow>
        </OreTableHead>
        <OreTableBody as="tbody" valign="top">
          {employees && employees.length <= 0 ? (
            <TableEmpty
              colSpan={7}
              firstText={t(unloadTranslation.searchNoFound.first)}
              linkText={t(unloadTranslation.searchNoFound.link)}
              endText={t(unloadTranslation.searchNoFound.end)}
              linkUrl={''}
            />
          ) : showSelected && employeesSelected.length > 0 ? (
            employeesSelected
              .slice((page - 1) * pageSize, page * pageSize)
              .map((employee: EmployeesWithLastCardNew, index: number) => {
                return GetRow({
                  index,
                  employee,
                  onSelectEmployee,
                  isSelected,
                  enabled: true,
                })
              })
          ) : (
            employees.map((employee: EmployeesWithLastCardNew, index: number) => {
              return employee.balance === '-' ||
                employee.balance === '0' ||
                employee.balance === '0€' ? (
                <OreTooltipCustom
                  text={t(
                    TTOrderTranslation.selectMultipleEmployees.table.emptyBalanceTooltip
                  )}
                  placement="left"
                  separation="xsmall"
                  key={'rows' + index}>
                  {GetRow({
                    index,
                    employee,
                    onSelectEmployee,
                    isSelected,
                    enabled: false,
                  })}
                </OreTooltipCustom>
              ) : (
                GetRow({ index, employee, onSelectEmployee, isSelected, enabled: true })
              )
            })
          )}
        </OreTableBody>
      </OreTable>
      <OreTableActions>
        <Grid gap="2rem">
          <OreText as="span" size="text-sm" tone="neutral">
            {employeesSelected.length}{' '}
            {employeesSelected.length == 1
              ? t(unloadTranslation.selectedRowsDescription)
              : t(unloadTranslation.selectedRowsDescriptionOthers)}
          </OreText>
        </Grid>
        <OrePagination
          count={showSelected ? employeesSelected.length : totalRows}
          labelNextPage={t(unloadTranslation.nextPageLabel)}
          labelPreviousPage={t(unloadTranslation.previousPageLabel)}
          onPageChange={onPageChange}
          page={page}
          rowsPerPage={pageSize}
          text={t(unloadTranslation.pageOf)}
        />
      </OreTableActions>
    </OreTableContainer>
  )
}
