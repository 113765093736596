import { t } from 'i18next'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TGDOrderService } from 'src/core/services/tgdOrderService'
import {
  EmployeesTGDOrderModel,
  TGDRepeatOrderModel,
  TGDRepeatOrderResponse,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import { createOrderId, createUserId } from 'src/core/helpers'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { GetExcelorderType } from 'src/core/services/excelService'
import { CaptionModel, RowModel, TableRowEspec } from 'src/domain/customComponents/table'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useCardOrder } from 'src/presentation/context/cardOrder/CardOrderProvider'
import { useUser } from 'src/presentation/context/user/UserProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useModalController } from 'src/presentation/components/Edenred'
import {
  MetaStatusCodes,
  NotificationSeverity,
  browserStorageKeys,
  cardRequestType,
  clientOrderTableHomeType,
  documentType,
  edenredProducts,
  excelRequestType,
  orderType,
} from 'src/domain/enum'
import {
  CardOrderModel,
  DeliverySiteModel,
  IncompleteOrderModel,
  LastOrderModel,
} from 'src/domain/models'
import { cardOrderService } from 'src/core/services'
import { FillRechargeConfigurationRows } from 'src/core/services/cardOrder'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { forms, myOrders } from 'src/domain/translations'
import {
  ClientHomeTablesContext,
  Default,
  LastOrderFavoriteTable,
  LastOrderRepetTable,
} from 'src/core/strategies'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'

interface OrderClientState {
  caption: CaptionModel
  rows: RowModel[]
  setRows: Dispatch<SetStateAction<RowModel[]>>
  setShowModalRepetExcel: Dispatch<SetStateAction<boolean>>
  showModalRepetExcel: boolean
  titleModalRepetExcel: string
  descriptionModalRepetExcel: string
  buttonsModalRepetExcel: PopupButtonModel[]
  htmlElementModalRepeatExcel: JSX.Element
}

export const useOrderClienteHome = (
  tableType: number,
  productCode?: number
): OrderClientState => {
  const { setConfigurationMode, addOrders, clearAll } = useCardOrder()
  const navigate = useNavigate()
  const { userRechargeOrderById } = useUser()
  const { startLoading, stopLoading } = useLoader()
  const [caption, setCaption] = useState<CaptionModel>({ description: '', title: '' })
  const [rows, setRows] = useState<RowModel[]>([])
  const [repetExcelOrderId, setRepetExcelOrderId] = useState<number>()
  const [repetExcelOrderType, setRepetExcelOrderType] = useState<number>()
  const { addNotification } = useNotification()

  const {
    show: showModalRepetExcel,
    title: titleModalRepetExcel,
    description: descriptionModalRepetExcel,
    buttons: buttonsModalRepetExcel,
    setShow: setShowModalRepetExcel,
    setDescription: setDescriptionModalRepetExcel,
    setTitle: setTitleModalRepetExcel,
    setButtons: setButtonsModalRepetExcel,
    htmlElementModal: htmlElementModalRepeatExcel,
    setHtmlElementModal: setHtmlElementModalRepeatExcel,
  } = useModalController()

  const OnClickRepetOrder = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rowId: number
  ): Promise<void> => {
    event.preventDefault()

    startLoading()
    const orderToRepeat = await getOrderToRepeat(rowId)
    stopLoading()

    if (!orderToRepeat) return

    if (
      orderToRepeat.orderType === orderType.Recharge &&
      orderToRepeat.isOrderCreatedByExcel
    ) {
      setRepetExcelOrderId(rowId)
      setRepetExcelOrderType(orderType.Recharge)
      setShowModalRepetExcel(true)
      return
    }
    if (orderToRepeat.orderType === orderType.Recharge) {
      RepetFormOrder(orderToRepeat.orderId)
      return
    }
    if (
      orderToRepeat.orderType === orderType.NurseryAssignmentOrder &&
      orderToRepeat.isOrderCreatedByExcel
    ) {
      setRepetExcelOrderId(rowId)
      setRepetExcelOrderType(orderType.NurseryAssignmentOrder)
      setShowModalRepetExcel(true)
      return
    }
    if (orderToRepeat.orderType === orderType.NurseryAssignmentOrder) {
      RepeatNurseryAssignmentOrder(orderToRepeat.orderId)
      return
    }
  }

  const getOrderToRepeat = (orderId: number): Promise<LastOrderModel | undefined> => {
    return new Promise<LastOrderModel | undefined>(resolve => {
      cardOrderService()
        .getLastOrders()
        .then(lastOrders => {
          const orderToRepeat = lastOrders.data.rows.find(
            (order: LastOrderModel) => order.orderId === orderId
          )

          if (orderToRepeat) {
            resolve(orderToRepeat)
          }
          resolve(undefined)
        })
    })
  }

  const mapOrderResponseToTTOrderModel = (orderResponse: IncompleteOrderModel): void => {
    const ttOrders: TTOrderModel[] = []
    orderResponse.cardOrders.forEach((order: CardOrderModel) => {
      const ttOrder: TTOrderModel = {
        birthDate: order.employeeData.birthDate ? order.employeeData.birthDate : '',
        companyId: order.employeeData.companyId ? order.employeeData.companyId : 0,
        corporativeEmail: order.employeeData.email ? order.employeeData.email : '',
        costCenter: order.employeeData.costCenter ? order.employeeData.costCenter : '',
        deliverySiteData: {} as DeliverySiteModel,
        deliverySiteId: order.sendData?.deliverySiteId
          ? order.sendData?.deliverySiteId
          : 0,
        document: order.employeeData.document ? order.employeeData.document : '',
        documentTypeId: order.employeeData.documentTypeId
          ? order.employeeData.documentTypeId
          : 0,
        employeeNumber: order.employeeData.employeeNumber
          ? order.employeeData.employeeNumber
          : '',
        firstSurname: order.employeeData.firstSurname
          ? order.employeeData.firstSurname
          : '',
        name: order.employeeData.name ? order.employeeData.name : '',
        secondSurname: order.employeeData.secondSurname
          ? order.employeeData.secondSurname
          : '',
        orderTypeId: order.orderTypeId,
        telephone: order.employeeData.telephone ? order.employeeData.telephone : '',
        userId: order.employeeData.userId ? order.employeeData.userId : 0,
        id: createOrderId(),
        cardData: {
          cardRequestType: cardRequestType.charge,
          cardRequestTypeId: order.cardData.cardRequestTypeId
            ? order.cardData.cardRequestTypeId
            : 0,
          initialAmount: order.cardData.initialAmount,
          productTypeId: edenredProducts.ticketTransporte,
          balance: order.cardData.balance,
          expiredDate: order.cardData.expiredDate ? order.cardData.expiredDate : '',
        },
      }
      ttOrders.push(ttOrder)
    })
    sessionStorage.setItem(browserStorageKeys.ttOrderEmployees, JSON.stringify(ttOrders))
    navigate(navigationRoutes.ttRechargeEmployeesConfiguration)
  }

  const RepetFormOrder = (orderId: number): void => {
    userRechargeOrderById(orderId).then(orderResponse => {
      if (orderResponse && orderResponse.cardOrders) {
        Seq(orderResponse.cardOrders).forEach((order: CardOrderModel, index: number) => {
          order.orderId = index + 1
          order.orderTypeId = orderType.Recharge
        })
        const rechargeRows: RowModel[] = FillRechargeConfigurationRows(
          orderResponse.cardOrders
        )
        switch (orderResponse.productCode) {
          case edenredProducts.ticketRestaurant:
            clearAll()
            addOrders(orderResponse.cardOrders)
            setConfigurationMode({
              editMode: true,
              entity: rechargeRows,
              repetOrder: true,
            })
            navigate(navigationRoutes.cardOrderRechargeConfigurationEdit)
            break
          case edenredProducts.ticketGuarderia:
            navigate(navigationRoutes.tgdOrderEmployeesConfiguration)
            break
          case edenredProducts.ticketTransporte:
            mapOrderResponseToTTOrderModel(orderResponse)
            break
        }
      }
    })
  }

  const RepeatNurseryAssignmentOrder = (orderId: number): void => {
    startLoading()
    TGDOrderService()
      .repeatAssignmentOrder(orderId)
      .then((response: TGDRepeatOrderResponse) => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          const employeeOrders: EmployeesTGDOrderModel[] =
            response.data.assignmentOrders.map(
              (orderItem: TGDRepeatOrderModel): EmployeesTGDOrderModel => {
                const mappedOrder: EmployeesTGDOrderModel = {
                  userId: createUserId(),
                  clientCode: 0,
                  productCode: edenredProducts.ticketGuarderia,
                  userDni: orderItem.employeeData.document,
                  documentTypeId: documentType.Default,
                  employeeNumber: '',
                  userName: `${orderItem.employeeData.name} ${orderItem.employeeData.firstSurname} ${orderItem.employeeData.secondSurname}`,
                  userFirstName: orderItem.employeeData.name,
                  userLastName: orderItem.employeeData.firstSurname,
                  userLastName2: orderItem.employeeData.secondSurname,
                  validUser: true,
                  userBirthdayDate: '',
                  userCorporativeEmail: '',
                  costCenter: orderItem.employeeData.costCenter || '',
                  userCorporativeTelephone: '',
                  userTelephone: '',
                  userEmail: '',
                  accountBalance: 0,
                  restrictionProfileId: 0,
                  userSon: {
                    userId: createUserId(),
                    userName: `${orderItem.employeeSon.firstName} ${orderItem.employeeSon.lastName} ${orderItem.employeeSon.lastName2}`,
                    userFirstName: orderItem.employeeSon.firstName,
                    userLastName: orderItem.employeeSon.lastName,
                    userLastName2: orderItem.employeeSon.lastName2,
                    userBirthdayDate: orderItem.employeeSon.birthDate,
                    validUser: true,
                    lastNurseryId: orderItem.employeeSon.kindergartenId,
                    lastNurseryName: orderItem.employeeSon.kindergartenName,
                    lastNurseryProvince: orderItem.employeeSon.kindergartenProvince,
                    lastNurseryCity: orderItem.employeeSon.kindergartenCity,
                    lastNurseryZipCode: orderItem.employeeSon.kindergartenZipCode,
                    lastNurseryStreetName: orderItem.employeeSon.kindergartenStreetName,
                    lastNurseryStreetType: orderItem.employeeSon.kindergartenStreetType,
                    lastNurseryStreetDescription1:
                      orderItem.employeeSon.kindergartenStreetDescription1,
                    lastNurseryStreetDescription2:
                      orderItem.employeeSon.kindergartenStreetDescription2,
                    lastNurseryStreetNumber:
                      orderItem.employeeSon.kindergartenStreetNumber,
                    costCenter: orderItem.employeeData.costCenter || '',
                    initialAmount: orderItem.cardData.initialAmount,
                  },
                }

                return mappedOrder
              }
            )

          // Save array to Session Storage Key
          sessionStorage.setItem(
            browserStorageKeys.tgdOrderEmployees,
            JSON.stringify(employeeOrders)
          )

          // Navigate to Config page
          navigate(navigationRoutes.tgdOrderEmployeesConfiguration)
        } else {
          if (response?.meta.messages.length > 0) {
            addNotification(t(forms.errors.genericError), NotificationSeverity.error)
          }
        }
      })
      .finally(() => stopLoading())
  }

  const downloadTRExcelOrder = (): void => {
    if (repetExcelOrderId) {
      startLoading()
      cardOrderService()
        .downloadOrderTemplate(
          excelRequestType.Recharge,
          edenredProducts.ticketRestaurant,
          true,
          repetExcelOrderId
        )
        .then(response => {
          const link = document.createElement('a')
          link.href = `data:application/octet-stream;base64,${response.data}`
          link.download = 'template.xlsx'
          link.click()
          link.remove()
        })
        .finally(() => {
          stopLoading()
        })
      setShowModalRepetExcel(false)
      navigate(navigationRoutes.cardOrderRechargeExcel)
    }
  }

  const downloadTGDExcelOrder = (): void => {
    const orderToRepeat = GetExcelorderType(edenredProducts.ticketGuarderia)
    if (repetExcelOrderId) {
      startLoading()
      cardOrderService()
        .downloadOrderTemplate(
          orderToRepeat.excelTemplate,
          edenredProducts.ticketGuarderia,
          true,
          repetExcelOrderId
        )
        .then(response => {
          const link = document.createElement('a')
          link.href = `data:application/octet-stream;base64,${response.data}`
          link.download = orderToRepeat.filename + '.xlsx'
          link.click()
          link.remove()
        })
        .finally(() => {
          stopLoading()
        })
      setShowModalRepetExcel(false)
      navigate(navigationRoutes.tgdOrderExcel)
    }
  }

  const SetModalAsTGD = (): void => {
    setTitleModalRepetExcel(t(TGDOrderTranslation.excel.repeat.title))
    setDescriptionModalRepetExcel(t(TGDOrderTranslation.excel.repeat.desc))
    const desc_1 = t(TGDOrderTranslation.excel.repeat.desc_1)
    const desc_2 = t(TGDOrderTranslation.excel.repeat.desc_2)
    const desc_3 = t(TGDOrderTranslation.excel.repeat.desc_3)
    const desc_4 = t(TGDOrderTranslation.excel.repeat.desc_4)
    const assignmentDescriptionHtml: JSX.Element = (
      <div>
        <ul className="modal-list-description">
          <li>{desc_1}</li>
          <li>{desc_2}</li>
          <li>{desc_3}</li>
          <li>{desc_4}</li>
        </ul>
      </div>
    )
    setHtmlElementModalRepeatExcel(assignmentDescriptionHtml)
  }

  const SetModalAsRecharge = (): void => {
    setTitleModalRepetExcel(t(myOrders.lastOrders.repetExcel.title))
    setDescriptionModalRepetExcel(t(myOrders.lastOrders.repetExcel.description))
    setHtmlElementModalRepeatExcel(<></>)
  }

  const GetStrategy = (): ClientHomeTablesContext<LastOrderModel> => {
    let strategy: ClientHomeTablesContext<LastOrderModel> = new ClientHomeTablesContext(
      new Default()
    )

    if (tableType === clientOrderTableHomeType.repeatOrder)
      strategy = new ClientHomeTablesContext(new LastOrderRepetTable())
    if (tableType === clientOrderTableHomeType.favorite)
      strategy = new ClientHomeTablesContext(new LastOrderFavoriteTable())
    return strategy
  }

  const GetRows = async (productCode?: number): Promise<void> => {
    const strategy: ClientHomeTablesContext<LastOrderModel> = GetStrategy()
    const tableRowsSpec: TableRowEspec<LastOrderModel> = await strategy.GetRows(
      productCode
    )
    setRows(tableRowsSpec.rows)
    setCaption(
      strategy.GetCaption(
        tableType === clientOrderTableHomeType.favorite
          ? tableRowsSpec.totalRows
          : undefined
      )
    )
    setTitleModalRepetExcel(t(myOrders.lastOrders.repetExcel.title))
    setDescriptionModalRepetExcel(t(myOrders.lastOrders.repetExcel.description))
  }

  const GetRowsAction = (): void => {
    const strategy: ClientHomeTablesContext<LastOrderModel> = GetStrategy()
    setRows(strategy.GetRowActions(rows, OnClickRepetOrder))
  }

  const SetButtonsRepetExcelModal = (): void => {
    setButtonsModalRepetExcel([
      {
        title: t(myOrders.lastOrders.repetExcel.cancel),
        category: 'tertiary',
        onClick: () => setShowModalRepetExcel(false),
        size: 'large',
      },
      {
        title: t(myOrders.lastOrders.repetExcel.download),
        category: 'primary',
        onClick:
          repetExcelOrderType === orderType.NurseryAssignmentOrder
            ? downloadTGDExcelOrder
            : downloadTRExcelOrder,
        size: 'large',
      },
    ])
  }

  //Load rows
  useEffect(() => {
    GetRows(productCode)
  }, [])

  //Set rows actions
  useEffect(() => {
    if (rows.length > 0) GetRowsAction()
  }, [rows])

  //Set excel Modal
  useEffect(() => {
    if (repetExcelOrderId) {
      SetButtonsRepetExcelModal()
      if (repetExcelOrderType === orderType.NurseryAssignmentOrder) {
        SetModalAsTGD()
      } else {
        SetModalAsRecharge()
      }
    }
  }, [repetExcelOrderId, repetExcelOrderType])

  return {
    caption,
    rows,
    setRows,
    setShowModalRepetExcel,
    showModalRepetExcel,
    titleModalRepetExcel,
    descriptionModalRepetExcel,
    buttonsModalRepetExcel,
    htmlElementModalRepeatExcel,
  }
}
