import {
  OreHeading,
  OreTab,
  OreTabs,
  OreText,
  SvgKindergarten,
  SvgRestaurant,
  SvgTransport,
} from '@edenredespana/oreneta'
import { useSearchParams } from 'react-router-dom'
import {
  AlignPosition,
  edenredProducts,
  edenredSubProducts,
  queryParam,
} from 'src/domain/enum'
import { useUser } from '../context/user/UserProvider'
import { UserContract } from 'src/domain/models'
import { productsTranslate } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { useDistributorController } from '../components/Edenred/distributor/hook/useDistributorController'
import { DistributorsContainer } from '../components/Edenred/distributor/organism/DistributorsContainer'
import { OrderHomeContainer } from '../components/cardOrder/orderHome/organism/OrderHomeContainer'
import { useEffect, useState } from 'react'
import cx from 'classnames'
import { Grid, GridItem } from '../components/Edenred/layout'
import KindergartenHomeImg from '../assets/img/KindergartenHome.png'
import RedCircleImg from '../assets/img/RedCircle.svg'
import { orderHomeTranslation } from 'src/domain/translations/cardOrder/home'
import { getTGDSubProductByContracts } from 'src/core/services/tgdBasicOrderService'
import { has } from 'lodash'

interface HeaderProps {
  title: string
  titleSize?:
    | 'title-xl'
    | 'title-lg'
    | 'title-md'
    | 'title-sm'
    | 'headline-lg'
    | 'headline-md'
    | 'headline-sm'
  subtitle?: string
  homeHero?: boolean
  reducedVersion?: boolean
}

export const NewOrderTemplate = ({
  title,
  titleSize,
  homeHero,
  reducedVersion = false,
}: HeaderProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const { contracts } = useUser()
  const [productCodesInContract, setProductCodesInContract] = useState<number[]>()
  const [TGDSubProduct, setTGDSubProduct] = useState<number>()
  const { t } = useTranslation()
  const {
    getAllDistributorsTR1,
    getAllDistributorsTR2,
    getAllDistributorsTT1,
    getAllDistributorsTT2,
    getTGDPlusDistributor,
    getAllDistributorsTGDBasic,
  } = useDistributorController()

  const getSelectedByUserContract = (): number => {
    // Check for each product in the desired order and return the first match
    const hasTR = contracts?.some(
      contract => contract.productCode === edenredProducts.ticketRestaurant
    )
    if (hasTR) return edenredProducts.ticketRestaurant

    const hasTT = contracts?.some(
      contract => contract.productCode === edenredProducts.ticketTransporte
    )
    if (hasTT) return edenredProducts.ticketTransporte

    const hasTGD = contracts?.some(
      contract => contract.productCode === edenredProducts.ticketGuarderia
    )
    if (hasTGD) return edenredProducts.ticketGuarderia

    // Default case if none of the products are found
    // You can return a default value or handle as needed
    return edenredProducts.ticketRestaurant // Assuming Ticket Restaurant is the default
  }

  const updateQueryParam = (): void => {
    const productParam = searchParams.get(queryParam.product)
    if (productParam) {
      const product = Number(productParam)
      switch (product) {
        case edenredProducts.ticketRestaurant:
          setSelectedTab(edenredProducts.ticketRestaurant)
          break
        case edenredProducts.ticketTransporte:
          setSelectedTab(edenredProducts.ticketTransporte)
          break
        case edenredProducts.ticketGuarderia:
          setSelectedTab(edenredProducts.ticketGuarderia)
          break
        default:
          selectTab(getSelectedByUserContract())
          break
      }
    }
    if (!productParam) {
      selectTab(getSelectedByUserContract())
    }
  }

  const handleTabClick = (index: number): void => {
    selectTab(index)
  }

  const selectTab = (index: number): void => {
    setSelectedTab(index)
    setSearchParams({ product: index.toString() })
  }

  useEffect(() => {
    if (contracts && contracts.length > 0) {
      const contractProducts: number[] = contracts.map(
        (contract: UserContract) => contract.productCode
      )
      setTGDSubProduct(getTGDSubProductByContracts(contracts))
      setProductCodesInContract(contractProducts)

      // Now that contracts are loaded, select the tab
      const productParam = searchParams.get(queryParam.product)
      if (!productParam) {
        selectTab(getSelectedByUserContract())
        return
      }
      updateQueryParam()
    }
  }, [contracts])

  return (
    <>
      <div
        className={cx(
          'hero',
          { 'hero--home': homeHero },
          { 'hero--reduced': reducedVersion }
        )}>
        <div className="hero__content">
          <OreHeading align="left" as="h1" size={titleSize} tone="neutral">
            <span className="hero__bullet"></span>
            {title}
          </OreHeading>
        </div>
        <div className="mt-2">
          <OreTabs>
            {/* Restaurant */}
            {productCodesInContract?.includes(edenredProducts.ticketRestaurant) && (
              <OreTab
                label={t(productsTranslate.ticketRestaurant)}
                handleClick={() => handleTabClick(edenredProducts.ticketRestaurant)}
                key="tab-1"
                active={selectedTab === edenredProducts.ticketRestaurant}
                icon={<SvgRestaurant />}></OreTab>
            )}
            {/* Transporte */}
            {productCodesInContract?.includes(edenredProducts.ticketTransporte) && (
              <OreTab
                label={t(productsTranslate.ticketTransporte)}
                handleClick={() => handleTabClick(edenredProducts.ticketTransporte)}
                key="tab-2"
                active={selectedTab === edenredProducts.ticketTransporte}
                icon={<SvgTransport />}></OreTab>
            )}
            {/* Guardería */}
            {productCodesInContract?.includes(edenredProducts.ticketGuarderia) && (
              <OreTab
                label={t(productsTranslate.ticketGuarderia)}
                handleClick={() => handleTabClick(edenredProducts.ticketGuarderia)}
                key="tab-3"
                active={selectedTab === edenredProducts.ticketGuarderia}
                icon={<SvgKindergarten />}></OreTab>
            )}
          </OreTabs>
        </div>
        <div role="tabpanel" style={{ marginTop: '2rem' }}>
          {/* Distributors */}
          {selectedTab === edenredProducts.ticketRestaurant && (
            <>
              <DistributorsContainer
                position={AlignPosition.horizontal}
                distributors={getAllDistributorsTR1()}
              />
              <DistributorsContainer
                position={AlignPosition.horizontal}
                distributors={getAllDistributorsTR2()}
              />
              <OrderHomeContainer productCode={edenredProducts.ticketRestaurant} />
            </>
          )}
          {selectedTab === edenredProducts.ticketTransporte && (
            <>
              <DistributorsContainer
                position={AlignPosition.horizontal}
                distributors={getAllDistributorsTT1()}
              />
              <DistributorsContainer
                position={AlignPosition.horizontal}
                distributors={getAllDistributorsTT2()}
              />
              <OrderHomeContainer productCode={edenredProducts.ticketTransporte} />
            </>
          )}
          {selectedTab === edenredProducts.ticketGuarderia &&
            (TGDSubProduct === edenredSubProducts.guarderia ? (
              // TGD Basic
              <>
                <DistributorsContainer
                  position={AlignPosition.horizontal}
                  distributors={getAllDistributorsTGDBasic()}
                />
              </>
            ) : (
              // TGD Plus
              <>
                <Grid>
                  <GridItem>
                    <OreText className="mb-2">
                      {t(orderHomeTranslation.kindergartenTabText)}
                    </OreText>
                    <DistributorsContainer
                      position={AlignPosition.horizontal}
                      distributors={getTGDPlusDistributor()}
                    />
                  </GridItem>
                </Grid>
                <OrderHomeContainer productCode={edenredProducts.ticketGuarderia} />
              </>
            ))}
        </div>
      </div>
    </>
  )
}
