import {
  SvgFile,
  SvgDownload,
  SvgRepeat,
  SvgForm,
  SvgExcel,
  OrePill,
} from '@edenredespana/oreneta'
import { t } from 'i18next'
import { Seq } from 'immutable'
import * as Yup from 'yup'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { UseFormRegister } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  createOrderId,
  createUserId,
  dateValueIsLowerThanTomorrow,
} from '../../../../../../core/helpers'
import { cardOrderService } from '../../../../../../core/services'
import { getLastOrdersMap } from '../../../../../../domain/adapters/cardOrder/getLastOrdersMap'
import type { LastOrderRequestBody } from '../../../../../../domain/bodyRequest/LastOrdersRequestBody'
import type { TextBoxRowModel } from '../../../../../../domain/customComponents'
import type { PopupButtonModel } from '../../../../../../domain/customComponents/Popup'
import type {
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
  FooterModel,
  TableEmptyModel,
} from '../../../../../../domain/customComponents/table'
import type {
  ActionControlModel,
  CaptionModel,
  HeaderModel,
  RowModel,
} from '../../../../../../domain/customComponents/table/TableModel'
import {
  browserStorageKeys,
  cardRequestType,
  documentType,
  edenredProducts,
  MetaStatusCodes,
  NotificationSeverity,
  orderStatus,
  orderType,
  proformaExcelType,
} from '../../../../../../domain/enum'
import type {
  CardOrderModel,
  DeliverySiteModel,
  HttpModel,
  IncompleteOrderModel,
} from '../../../../../../domain/models'
import type {
  LastOrderFavoriteModel,
  LastOrderModel,
  LastOrderModelResponse,
} from '../../../../../../domain/models/cardOrder/LastOrderModel'
import {
  cardOrderTranslation,
  deliverySiteTranslation,
  duplicatesConfigurationTranslations,
  forms,
} from '../../../../../../domain/translations'
import { myOrders } from '../../../../../../domain/translations/cardOrder/myOrders'
import { useLoader } from '../../../../../context/loader/LoaderProvider'
import { useAsync } from '../../../../../hooks'
import { useModalController } from '../../../../Edenred'
import { yupResolver } from '@hookform/resolvers/yup'
import { useUser } from '../../../../../context/user/UserProvider'
import { useCardOrder } from '../../../../../context/cardOrder/CardOrderProvider'
import { navigationRoutes } from '../../../../../../config/constants/navigationRoutes'
import { FillRechargeConfigurationRows } from '../../../../../../core/services/cardOrder'
import { dash } from '../../../../../../domain/constants/symbols'
import { getProductIconByProductType } from 'src/Flex/Products/ui/shared/atoms/getProductIconByProductType'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { GetExcelorderType } from 'src/core/services/excelService'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { TGDOrderService } from 'src/core/services/tgdOrderService'
import {
  EmployeesTGDOrderModel,
  TGDRepeatOrderModel,
  TGDRepeatOrderResponse,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'

export interface ConfigurationState {
  header: HeaderModel
  caption: CaptionModel
  rows: RowModel[]
  footer: FooterModel
  setRows: Dispatch<SetStateAction<RowModel[]>>
  setShowPopup: Dispatch<SetStateAction<boolean>>
  popupShow: boolean
  popupTitle: string
  popupDescription: string
  popupButtons: PopupButtonModel[]
  navigate: NavigateFunction
  filter: FilterModel
  setFilter: Dispatch<SetStateAction<FilterModel>>
  onClickFilter: () => void
  getFormModalFavorite: () => TextBoxRowModel<LastOrderFavoriteModel>[]
  register: UseFormRegister<LastOrderFavoriteModel>
  errors: any
  pageSize: number
  //Modal repet excel
  setShowModalRepetExcel: Dispatch<SetStateAction<boolean>>
  showModalRepetExcel: boolean
  titleModalRepetExcel: string
  descriptionModalRepetExcel: string
  htmlModalElement: JSX.Element
  buttonsModalRepetExcel: PopupButtonModel[]
  tableEmpty: TableEmptyModel
}

interface Filters {
  orderIdOrInvoiceNumber?: string
  productCode?: number
  status?: number
  dateFrom?: string
  dateTo?: string
}

interface Props {
  showFilter: boolean
  pageSize: number
  showAllTotalRow?: boolean
}

export const useLastOrders = ({
  pageSize,
  showFilter,
  showAllTotalRow,
}: Props): ConfigurationState => {
  const navigate = useNavigate()
  const { userRechargeOrderById } = useUser()
  const { setConfigurationMode, addOrders, clearAll } = useCardOrder()
  const [lastOrders, setLastOrders] = useState<LastOrderModel[]>([])
  const [rows, setRows] = useState<RowModel[]>([])
  const [page, setPage] = useState(1)
  const [filter, setFilter] = useState<FilterModel>({})
  const [footer, setFooter] = useState<FooterModel>({})
  const [repetExcelOrderId, setRepetExcelOrderId] = useState<number>()
  const [orderToRepeat, setOrderToRepeat] = useState<LastOrderModel>()
  const isServerSide = false
  const [caption, setCaption] = useState<CaptionModel>({
    title: t(myOrders.lastOrders.table.caption.title),
    description: showAllTotalRow ? t(myOrders.incompleteOrders.table.caption.orders) : '',
  })
  const { addNotification } = useNotification()

  const formSchema = Yup.object({
    favName: Yup.string().required(t(forms.errors.fieldRequired)),
    reminderDate: Yup.string().test(
      'reminderDate',
      t(cardOrderTranslation.form.errors.dateBeforeToday),
      value => {
        const error = value ? dateValueIsLowerThanTomorrow(value) : null
        return !error
      }
    ),
    reminderEmail: Yup.string()
      .email(t(forms.errors.invalidFormat))
      .when('reminderDate', (reminderDate, schema) => {
        if (reminderDate) return schema.required(t(forms.errors.fieldRequired))
        return schema
      }),
  })

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm<LastOrderFavoriteModel>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      orderId: 0,
      isFavorite: false,
      favName: '',
      reminderDate: '',
      reminderEmail: '',
    },
  })
  const { startLoading, stopLoading } = useLoader()

  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const {
    show: showModalRepetExcel,
    title: titleModalRepetExcel,
    description: descriptionModalRepetExcel,
    buttons: buttonsModalRepetExcel,
    setShow: setShowModalRepetExcel,
    setDescription: setDescriptionModalRepetExcel,
    setTitle: setTitleModalRepetExcel,
    setButtons: setButtonsModalRepetExcel,
    htmlElementModal: htmlElementModal,
    setHtmlElementModal: setHtmlElementModal,
  } = useModalController()

  const { state: lastOrdersResponse } = useAsync<LastOrderModelResponse>(
    () => GetLastOrders({}),
    page
  )

  const GetLastOrders = (
    filters: LastOrderRequestBody
  ): Promise<HttpModel<LastOrderModelResponse>> => {
    return cardOrderService().getLastOrders(filters)
  }

  const header: HeaderModel = {
    headerElement: <SvgFile />,
    headerLabel: [
      { label: t(myOrders.lastOrders.table.header.orderCreationDate) },
      { label: t(myOrders.lastOrders.table.header.orderId) },
      { label: t(myOrders.lastOrders.table.header.invoiceNumber) },
      { label: t(myOrders.lastOrders.table.header.productDescription) },
      { label: t(myOrders.lastOrders.table.header.orderTypeDescription) },
      { label: t(myOrders.lastOrders.table.header.statusDescription) },
      { label: t(myOrders.lastOrders.table.header.amount) },
      { label: t(myOrders.lastOrders.table.header.actions) },
    ],
  }
  const onClickFilter = () => {
    // event.preventDefault()
    CallBackEndForFilterQuery()
  }

  const CallBackEndForFilterQuery = () => {
    startLoading()
    const tableFilters: Filters = SetFilterValues()
    GetLastOrders({
      orderIdOrInvoiceNumber: tableFilters.orderIdOrInvoiceNumber,
      productCode: tableFilters.productCode,
      orderStatus: tableFilters.status,
      beginDate: tableFilters.dateFrom,
      endDate: tableFilters.dateTo,
    })
      .then(lastOrdersFromBackEnd => {
        setPage(1)
        const lastOrdersMapped = getLastOrdersMap(lastOrdersFromBackEnd.data.rows)
        setLastOrders(lastOrdersMapped)
        stopLoading()
      })
      .finally(() => stopLoading())
  }

  const SetFilterValues = (): Filters => {
    const tableFilters: Filters = {}
    if (filter.search && filter.search.textBox.value !== '')
      tableFilters.orderIdOrInvoiceNumber = filter.search.textBox.value?.trim()
    if (filter.filterFields && filter.filterFields.length > 0) {
      Seq(filter.filterFields).forEach((fields: FilterFieldModel) => {
        Seq(fields.filterFields).forEach((field: FilterControlModel) => {
          if (field.name === 'productCode' && field.value !== '')
            tableFilters.productCode = Number(field.value)
          if (field.name === 'status' && field.value !== '')
            tableFilters.status = Number(field.value)
          if (field.name === 'orderCreationDateFrom' && field.value !== '')
            tableFilters.dateFrom = field.value
          if (field.name === 'orderCreationDateTo' && field.value !== '')
            tableFilters.dateTo = field.value
        })
      })
    }
    return tableFilters
  }

  const GetOrderStatus = (): [string, number][] => {
    const options: [string, number][] = []
    Seq(lastOrders).forEach((lastOrder: LastOrderModel) => {
      if (!options.find((option: [string, number]) => option[1] === lastOrder.status))
        options.push([lastOrder.statusDescription, lastOrder.status])
    })
    return options
  }

  const GetFormatDates = (): string[] => {
    const dates: string[] = []
    Seq(lastOrders).forEach((lastOrder: LastOrderModel) => {
      const splitDate: string[] = lastOrder.orderCreationDate.split('/')
      const formatDate =
        splitDate[2] +
        dash +
        splitDate[1].padStart(2, '0') +
        dash +
        splitDate[0].padStart(2, '0')
      if (!dates.find((date: string) => date === formatDate)) dates.push(formatDate)
    })
    return dates
  }

  const GetMinDate = (): string => {
    const dates: string[] = GetFormatDates()
    if (dates.length > 0) return dates[dates.length - 1]
    return ''
  }

  const GetMaxDate = (): string => {
    const dates: string[] = GetFormatDates()
    if (dates.length > 0) return dates[0]
    return ''
  }

  const GetFilters = () => {
    setFilter({
      search: {
        textBox: {
          fields: ['orderId', 'invoiceNumber'],
          placeHolder: t(myOrders.filter.search.lastOrderPlaceHolder),
          value: '',
        },
        searchButton: t(myOrders.filter.search.button),
      },
      filerOptions: [
        {
          label: t(myOrders.filter.filterOptions.label),
          name: 'isOrderFavorite',
          checked: false,
        },
      ],
      resetButton: t(myOrders.filter.resetButtons),
      filterButtonLabel: t(forms.filter.label),
      filterFields: [
        {
          formClass: 'form-atom form-atom--half',
          filterFields: [
            {
              filterType: 'comboBox',
              name: 'productCode',
              label: t(myOrders.filter.filterFields.product.label),
              placeHolder: t(myOrders.filter.filterFields.product.placeholder),
              value: '',
              options: [
                {
                  options: [
                    [
                      t(flexProductsTranslations.ids[19]),
                      edenredProducts.ticketRestaurant,
                    ],
                  ],
                },
                {
                  options: [
                    [t(flexProductsTranslations.ids[7]), edenredProducts.ticketGuarderia],
                  ],
                },
              ],
            },
            {
              filterType: 'comboBox',
              name: 'status',
              label: t(myOrders.filter.filterFields.status.label),
              placeHolder: t(myOrders.filter.filterFields.status.placeholder),
              value: '',
              options: [{ options: GetOrderStatus() }],
            },
          ],
        },
        {
          formClass: 'form-atom form-atom--half',
          filterFields: [
            {
              filterType: 'datePicker',
              name: 'orderCreationDateFrom',
              label: t(myOrders.filter.filterFields.dateFrom.label),
              placeHolder: t(
                myOrders.filter.filterFields.dateFrom.placeholder
              ).toUpperCase(),
              value: '',
              min: GetMinDate(),
              max: GetMaxDate(),
            },
            {
              filterType: 'datePicker',
              name: 'orderCreationDateTo',
              label: t(myOrders.filter.filterFields.dateTo.label),
              placeHolder: t(
                myOrders.filter.filterFields.dateTo.placeholder
              ).toUpperCase(),
              value: '',
              min: GetMinDate(),
              max: GetMaxDate(),
            },
          ],
        },
      ],
    })
  }

  const GetStatusPillById = (
    status: number
  ): 'success-600' | 'warning-600' | 'error-600' | 'cobalt-600' | 'grey' | undefined => {
    switch (status) {
      case orderStatus.placed:
        return 'success-600'
      case orderStatus.requested:
        return 'warning-600'
      case orderStatus.cancelled:
        return 'error-600'
      case orderStatus.inProcess:
        return 'cobalt-600'
      default:
        return 'grey'
    }
  }

  const onChangeFavOrder = (orderId: number, isOrderFavorite: boolean) => {
    const favOrder: LastOrderFavoriteModel = {
      orderId: orderId,
      isFavorite: !isOrderFavorite,
    }
    reset(prevFavOrder => {
      return {
        ...prevFavOrder,
        ...favOrder,
      }
    })
    setShowPopup(!isOrderFavorite)
    if (isOrderFavorite) updateFavOrder(favOrder)
  }

  const downloadLastOrderDocument = (orderId: number, orderTypeId: number) => {
    if (
      orderTypeId !== orderType.Card &&
      orderTypeId !== orderType.Recharge &&
      orderTypeId !== orderType.NurseryAssignmentOrder
    )
      return
    startLoading()
    let proformaType = 0
    let proformaFileName = ''
    switch (orderTypeId) {
      case orderType.Card:
        proformaType = proformaExcelType.card
        proformaFileName = t(myOrders.lastOrders.table.action.proformaCardsFileName)
        break
      case orderType.Recharge:
        proformaType = proformaExcelType.credit
        proformaFileName = t(myOrders.lastOrders.table.action.proformaRechargeFileName)
        break
      case orderType.NurseryAssignmentOrder:
        proformaType = proformaExcelType.nursery
        proformaFileName = t(myOrders.lastOrders.table.action.proformaTGDFileName)
        break
      default:
        proformaType = proformaExcelType.card
        proformaFileName = t(myOrders.lastOrders.table.action.proformaCardsFileName)
        break
    }
    cardOrderService()
      .downloadLastOrderDocument(orderId, proformaType)
      .then(response => {
        if (response.meta.status === MetaStatusCodes.ERROR) {
          addNotification(t(forms.errors.genericError), NotificationSeverity.error)
          return
        }
        const link = document.createElement('a')
        link.href = `data:application/octet-stream;base64,${response.data}`
        link.download = `${proformaFileName}.xlsx`
        link.click()
        link.remove()
      })
      .finally(() => {
        stopLoading()
      })
    setShowPopup(false)
  }

  // set cell actions
  const GetCellActions = (order: LastOrderModel) => {
    const orderTypeId = order.orderType
    const orderId = order.orderId
    const isOrderFavorite = order.isOrderFavorite
    if (orderTypeId === orderType.Unload) return []

    let cellActions: ActionControlModel[] = [
      {
        tooltip: t(forms.tooltips.proformaSigning),
        name: 'download',
        icon: <SvgDownload />,
        type: 'download',
        onClick: () => downloadLastOrderDocument(orderId, orderTypeId),
      },
    ]

    if (
      orderTypeId === orderType.Recharge ||
      orderTypeId === orderType.NurseryAssignmentOrder
    ) {
      cellActions = cellActions.concat([
        {
          name: 'favs',
          type: 'favs',
          tooltip: isOrderFavorite
            ? t(deliverySiteTranslation.table.head.favUncheckedTooltip)
            : t(deliverySiteTranslation.table.head.favCheckedTooltip),
          checked: isOrderFavorite,
          onChange: () => onChangeFavOrder(orderId, isOrderFavorite),
        },
      ])
    }
    if (
      orderTypeId === orderType.Recharge ||
      orderTypeId === orderType.NurseryAssignmentOrder
    ) {
      cellActions = cellActions.concat([
        {
          tooltip: t(forms.buttons.repeatOrder),
          name: 'refresh',
          icon: <SvgRepeat />,
          type: 'refresh',
          onClick: () => OnclickRepeatOrder(order),
        },
      ])
    }
    return cellActions
  }

  const OnclickRepeatOrder = (order: LastOrderModel): void => {
    if (order.isOrderCreatedByExcel) {
      RepetExcelOrder(order)
      return
    }
    if (order.orderType === orderType.Recharge) {
      RepetFormOrder(order.orderId)
      return
    }
    if (order.orderType === orderType.NurseryAssignmentOrder) {
      RepeatNurseryAssignmentOrder(order.orderId)
      return
    }
  }

  const SetModalAsRecharge = (): void => {
    setHtmlElementModal(<></>)
    setTitleModalRepetExcel(t(myOrders.lastOrders.repetExcel.title))
    setDescriptionModalRepetExcel(t(myOrders.lastOrders.repetExcel.description))
  }

  const SetModalAsTGD = (): void => {
    setTitleModalRepetExcel(t(TGDOrderTranslation.excel.repeat.title))
    setDescriptionModalRepetExcel(t(TGDOrderTranslation.excel.repeat.desc))
    const desc_1 = t(TGDOrderTranslation.excel.repeat.desc_1)
    const desc_2 = t(TGDOrderTranslation.excel.repeat.desc_2)
    const desc_3 = t(TGDOrderTranslation.excel.repeat.desc_3)
    const desc_4 = t(TGDOrderTranslation.excel.repeat.desc_4)
    const assignmentDescriptionHtml: JSX.Element = (
      <div>
        <ul className="modal-list-description">
          <li>{desc_1}</li>
          <li>{desc_2}</li>
          <li>{desc_3}</li>
          <li>{desc_4}</li>
        </ul>
      </div>
    )
    setHtmlElementModal(assignmentDescriptionHtml)
  }

  const RepetExcelOrder = (order: LastOrderModel): boolean => {
    if (order.isOrderCreatedByExcel) {
      if (order.productCode === edenredProducts.ticketGuarderia) {
        SetModalAsTGD()
      } else {
        SetModalAsRecharge()
      }
      setOrderToRepeat(order)
      setShowModalRepetExcel(order.isOrderCreatedByExcel)
      setRepetExcelOrderId(order.orderId)
    }
    return order.isOrderCreatedByExcel
  }

  const mapOrderResponseToTTOrderModel = (orderResponse: IncompleteOrderModel): void => {
    const ttOrders: TTOrderModel[] = []
    orderResponse.cardOrders.forEach((order: CardOrderModel) => {
      const ttOrder: TTOrderModel = {
        birthDate: order.employeeData.birthDate ? order.employeeData.birthDate : '',
        companyId: order.employeeData.companyId ? order.employeeData.companyId : 0,
        corporativeEmail: order.employeeData.email ? order.employeeData.email : '',
        costCenter: order.employeeData.costCenter ? order.employeeData.costCenter : '',
        deliverySiteData: {} as DeliverySiteModel,
        deliverySiteId: order.sendData?.deliverySiteId
          ? order.sendData?.deliverySiteId
          : 0,
        document: order.employeeData.document ? order.employeeData.document : '',
        documentTypeId: order.employeeData.documentTypeId
          ? order.employeeData.documentTypeId
          : 0,
        employeeNumber: order.employeeData.employeeNumber
          ? order.employeeData.employeeNumber
          : '',
        firstSurname: order.employeeData.firstSurname
          ? order.employeeData.firstSurname
          : '',
        name: order.employeeData.name ? order.employeeData.name : '',
        secondSurname: order.employeeData.secondSurname
          ? order.employeeData.secondSurname
          : '',
        orderTypeId: order.orderTypeId,
        telephone: order.employeeData.telephone ? order.employeeData.telephone : '',
        userId: order.employeeData.userId ? order.employeeData.userId : 0,
        id: createOrderId(),
        cardData: {
          cardRequestType: cardRequestType.charge,
          cardRequestTypeId: order.cardData.cardRequestTypeId
            ? order.cardData.cardRequestTypeId
            : 0,
          initialAmount: order.cardData.initialAmount,
          productTypeId: edenredProducts.ticketTransporte,
          balance: order.cardData.balance,
          expiredDate: order.cardData.expiredDate ? order.cardData.expiredDate : '',
        },
      }
      ttOrders.push(ttOrder)
    })
    sessionStorage.setItem(browserStorageKeys.ttOrderEmployees, JSON.stringify(ttOrders))
    navigate(navigationRoutes.ttRechargeEmployeesConfiguration)
  }

  const RepetFormOrder = (orderId: number): void => {
    userRechargeOrderById(orderId).then(orderResponse => {
      if (orderResponse && orderResponse.cardOrders) {
        Seq(orderResponse.cardOrders).forEach((order: CardOrderModel, index: number) => {
          order.orderId = index + 1
          order.orderTypeId = orderType.Recharge
        })
        const rechargeRows: RowModel[] = FillRechargeConfigurationRows(
          orderResponse.cardOrders
        )
        switch (orderResponse.productCode) {
          case edenredProducts.ticketRestaurant:
            clearAll()
            addOrders(orderResponse.cardOrders)
            setConfigurationMode({
              editMode: true,
              entity: rechargeRows,
              repetOrder: true,
            })
            navigate(navigationRoutes.cardOrderRechargeConfigurationEdit)
            break
          case edenredProducts.ticketGuarderia:
            navigate(navigationRoutes.tgdOrderEmployeesConfiguration)
            break
          case edenredProducts.ticketTransporte:
            //TODO: mapear cardData to TTOrderModel
            mapOrderResponseToTTOrderModel(orderResponse)
            break
        }
      }
    })
  }

  const RepeatNurseryAssignmentOrder = (orderId: number): void => {
    startLoading()
    TGDOrderService()
      .repeatAssignmentOrder(orderId)
      .then((response: TGDRepeatOrderResponse) => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          const employeeOrders: EmployeesTGDOrderModel[] =
            response.data.assignmentOrders.map(
              (orderItem: TGDRepeatOrderModel): EmployeesTGDOrderModel => {
                const mappedOrder: EmployeesTGDOrderModel = {
                  userId: createUserId(),
                  clientCode: 0,
                  productCode: edenredProducts.ticketGuarderia,
                  userDni: orderItem.employeeData.document,
                  documentTypeId: documentType.Default,
                  employeeNumber: '',
                  userName: `${orderItem.employeeData.name} ${orderItem.employeeData.firstSurname} ${orderItem.employeeData.secondSurname}`,
                  userFirstName: orderItem.employeeData.name,
                  userLastName: orderItem.employeeData.firstSurname,
                  userLastName2: orderItem.employeeData.secondSurname,
                  validUser: true,
                  userBirthdayDate: '',
                  userCorporativeEmail: '',
                  costCenter: orderItem.employeeData.costCenter || '',
                  userCorporativeTelephone: '',
                  userTelephone: '',
                  userEmail: '',
                  accountBalance: 0,
                  restrictionProfileId: 0,
                  userSon: {
                    userId: createUserId(),
                    userName: `${orderItem.employeeSon.firstName} ${orderItem.employeeSon.lastName} ${orderItem.employeeSon.lastName2}`,
                    userFirstName: orderItem.employeeSon.firstName,
                    userLastName: orderItem.employeeSon.lastName,
                    userLastName2: orderItem.employeeSon.lastName2,
                    userBirthdayDate: orderItem.employeeSon.birthDate,
                    validUser: true,
                    lastNurseryId: orderItem.employeeSon.kindergartenId,
                    lastNurseryName: orderItem.employeeSon.kindergartenName,
                    lastNurseryProvince: orderItem.employeeSon.kindergartenProvince,
                    lastNurseryCity: orderItem.employeeSon.kindergartenCity,
                    lastNurseryZipCode: orderItem.employeeSon.kindergartenZipCode,
                    lastNurseryStreetName: orderItem.employeeSon.kindergartenStreetName,
                    lastNurseryStreetType: orderItem.employeeSon.kindergartenStreetType,
                    lastNurseryStreetDescription1:
                      orderItem.employeeSon.kindergartenStreetDescription1,
                    lastNurseryStreetDescription2:
                      orderItem.employeeSon.kindergartenStreetDescription2,
                    lastNurseryStreetNumber:
                      orderItem.employeeSon.kindergartenStreetNumber,
                    costCenter: orderItem.employeeData.costCenter || '',
                    initialAmount: orderItem.cardData.initialAmount,
                  },
                }

                return mappedOrder
              }
            )

          // Save array to Session Storage Key
          sessionStorage.setItem(
            browserStorageKeys.tgdOrderEmployees,
            JSON.stringify(employeeOrders)
          )

          // Navigate to Config page
          navigate(navigationRoutes.tgdOrderEmployeesConfiguration)
        } else {
          if (response?.meta.messages.length > 0) {
            addNotification(t(forms.errors.genericError), NotificationSeverity.error)
          }
        }
      })
      .finally(() => stopLoading())
  }

  const OnClickDownloadExcelOreder = (productId: number): void => {
    const orderToRepeat = GetExcelorderType(productId)
    if (repetExcelOrderId) {
      startLoading()
      cardOrderService()
        .downloadOrderTemplate(
          orderToRepeat.excelTemplate,
          productId,
          true,
          repetExcelOrderId
        )
        .then(response => {
          const link = document.createElement('a')
          link.href = `data:application/octet-stream;base64,${response.data}`
          link.download = orderToRepeat.filename + '.xlsx'
          link.click()
          link.remove()
        })
        .finally(() => {
          stopLoading()
        })
      setShowModalRepetExcel(false)
      navigate(orderToRepeat.redirectUrlAfterDownload)
    }
  }

  const updateFavOrder = (lastOrder: LastOrderFavoriteModel) => {
    startLoading()
    cardOrderService()
      .updateLastOrderFav(lastOrder)
      .then(() => {
        CallBackEndForFilterQuery()
        setValue('favName', '')
      })
      .finally(() => stopLoading())

    setShowPopup(false)
  }

  const GetRows = () => {
    const lastOrdersRows: RowModel[] = []
    Seq(lastOrders).forEach((lastOrder: LastOrderModel) => {
      const lastOrderRow: RowModel = {
        rowId: lastOrder.orderId,
        cells: [
          {
            type: 'element',
            cellElement: lastOrder.isOrderCreatedByExcel ? (
              <div className="svgExcel">
                <SvgExcel />
              </div>
            ) : (
              <div className="svgForm">
                <SvgForm />
              </div>
            ),
          },
          {
            type: 'text',
            cellText: [
              {
                text: lastOrder.orderCreationDate,
                name: 'orderCreationDate',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              { text: lastOrder.orderId.toString(), name: 'orderId', size: 'text-sm' },
            ],
          },
          {
            type: 'text',
            cellText: [
              { text: lastOrder.invoiceNumber, name: 'invoiceNumber', size: 'text-sm' },
            ],
          },
          {
            type: 'element',
            cellElement: (
              <div
                style={{
                  backgroundColor: '#e2e8f0',
                  borderRadius: '0.3rem',
                  width: '1.5rem',
                }}>
                {getProductIconByProductType(lastOrder.productCode)}
              </div>
            ),
          },
          {
            type: 'text',
            cellText: [
              {
                text: t(lastOrder.orderTypeDescription),
                name: 'orderTypeDescription',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'element',
            cellElement: (
              <OrePill tone={GetStatusPillById(lastOrder.status)}>
                {lastOrder.statusDescription}
              </OrePill>
            ),
          },
          {
            type: 'text',
            cellText: [
              {
                text: lastOrder.invoiceTotalAmount,
                name: 'invoiceTotalAmount',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'actions',
            cellActions: GetCellActions(lastOrder),
          },
        ],
        cellHidden: [{ name: 'isOrderFavorite', value: lastOrder.isOrderFavorite }],
      }
      lastOrdersRows.push(lastOrderRow)
    })
    setRows(lastOrdersRows)
  }

  const SetPopupButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.save),
        category: 'primary',
        onClick: handleSubmit(updateFavOrder),
        size: 'large',
      },
    ]
  }

  const getFormModalFavorite = (): TextBoxRowModel<LastOrderFavoriteModel>[] => {
    return [
      {
        className: 'form-atom',
        textBoxes: [
          {
            id: 'favName',
            label: t(myOrders.lastOrders.modal.favs.orderName),
            name: 'favName',
            placeHolder: t(myOrders.lastOrders.modal.favs.orderNamePlaceHolder),
            required: true,
            type: 'text',
            googleApi: false,
            maxLength: 100,
          },
        ],
      },
    ]
  }

  const onPageChange = (page: number) => {
    setPage(page)
  }

  const getFooter = () => {
    setFooter({
      pagination: {
        onPageChange: onPageChange,
        labelNextPage: t(forms.pagination.next),
        labelPreviousPage: t(forms.pagination.back),
        rowsPerPage: pageSize,
        text: t(forms.pagination.of),
        page: page,
        isServerSide: isServerSide,
        totalRows: lastOrdersResponse?.totalRows,
      },
    })
  }

  const SetButtonsRepetExcelModal = (productId: number | undefined): void => {
    if (!productId) return
    setButtonsModalRepetExcel([
      {
        title: t(myOrders.lastOrders.repetExcel.cancel),
        category: 'tertiary',
        onClick: () => setShowModalRepetExcel(false),
        size: 'large',
      },
      {
        title: t(myOrders.lastOrders.repetExcel.download),
        category: 'primary',
        onClick: () => OnClickDownloadExcelOreder(productId),
        size: 'large',
      },
    ])
  }

  const tableEmpty: TableEmptyModel = {
    colSpan: 7,
    firstText: t(duplicatesConfigurationTranslations.tableEmpty.first),
    linkText: t(duplicatesConfigurationTranslations.tableEmpty.link),
    linkUrl: navigationRoutes.duplicatedCard,
    endText: t(duplicatesConfigurationTranslations.tableEmpty.end),
  }

  useEffect(() => {
    getFooter()
  }, [page])

  useEffect(() => {
    if (!showFilter) return
    setPage(1)
  }, [filter])

  useEffect(() => {
    setTitlePopup(t(myOrders.lastOrders.modal.favs.title))
    setDescriptionPopup(t(myOrders.lastOrders.modal.favs.description))
    setTitleModalRepetExcel(t(myOrders.lastOrders.repetExcel.title))
    setDescriptionModalRepetExcel(t(myOrders.lastOrders.repetExcel.description))
  }, [])

  useEffect(() => {
    GetRows()
  }, [lastOrders])

  useEffect(() => {
    if (rows.length > 0) {
      setCaption(prevCaption => {
        return {
          ...prevCaption,
          rowCount: lastOrdersResponse?.totalRows,
        }
      })
      setButtonsPopup(SetPopupButtons())
      if (!showFilter) return
      GetFilters()
    }
  }, [rows])

  useEffect(() => {
    if (lastOrdersResponse) {
      const lastOrdersMapped = getLastOrdersMap(lastOrdersResponse.rows)
      setLastOrders(lastOrdersMapped)
      getFooter()
    }
  }, [lastOrdersResponse?.rows])

  useEffect(() => {
    if (repetExcelOrderId) SetButtonsRepetExcelModal(orderToRepeat?.productCode)
  }, [repetExcelOrderId])

  return {
    caption,
    header,
    rows,
    setRows,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    navigate,
    footer,
    filter,
    setFilter,
    onClickFilter,
    getFormModalFavorite,
    register,
    errors,
    pageSize,
    setShowModalRepetExcel,
    showModalRepetExcel,
    titleModalRepetExcel,
    descriptionModalRepetExcel,
    htmlModalElement: htmlElementModal,
    buttonsModalRepetExcel,
    tableEmpty,
  }
}
