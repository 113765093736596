import {
  ConvertStringDateToISOOnly,
  ConvertToISODateOnly,
  createUserId,
} from 'src/core/helpers'
import { edenredProducts } from '../enum'
import { CardOrderModel } from '../models'
import {
  TTOrderModel,
  TTOrderSummaryForm,
  TTOrderSummaryRequestModel,
} from '../models/TTOrder/TTOrderModel'

export const mapTTOrdersToCardOrders = (ttOrders: TTOrderModel[]): CardOrderModel[] => {
  const mappedOrdersArray: CardOrderModel[] = ttOrders.map(ttOrder => {
    const mappedOrder: CardOrderModel = {
      orderId: createUserId(),
      orderTypeId: ttOrder.orderTypeId,
      cardData: {
        productTypeId: edenredProducts.ticketTransporte,
        initialAmount: ttOrder.cardData.initialAmount,
        cardTypeId: ttOrder.cardData.cardRequestType,
        cardRequestTypeId: ttOrder.cardData.cardRequestTypeId,
      },
      employeeData: {
        email: ttOrder.corporativeEmail,
        name: ttOrder.name,
        firstSurname: ttOrder.firstSurname,
        secondSurname: ttOrder.secondSurname,
        document: ttOrder.document,
        documentTypeId: ttOrder.documentTypeId,
        employeeNumber: ttOrder.employeeNumber,
        companyId: ttOrder.companyId,
        birthDate: ConvertStringDateToISOOnly(ttOrder.birthDate),
        costCenter: ttOrder.costCenter,
      },
      sendData: {
        alias: ttOrder.deliverySiteData.alias,
        city: ttOrder.deliverySiteData.city,
        contactName: ttOrder.deliverySiteData.contactName,
        deliverySiteId: ttOrder.deliverySiteId,
        deliveryTypeId: ttOrder.deliverySiteData.deliveryType,
        email: ttOrder.deliverySiteData.email,
        name: ttOrder.deliverySiteData.deliveryPointName,
        observation: ttOrder.deliverySiteData.observation,
        province: ttOrder.deliverySiteData.province,
        streetDescription: ttOrder.deliverySiteData.streetDescription,
        streetName: ttOrder.deliverySiteData.streetName,
        streetNumber: ttOrder.deliverySiteData.streetNumber,
        telephone: ttOrder.deliverySiteData.telephone,
        zipCode: ttOrder.deliverySiteData.zipCode,
      },
    }
    return mappedOrder
  })

  return mappedOrdersArray
}

export const SetTTRequestCheckoutBody = (
  summary: TTOrderSummaryForm,
  ttOrders: TTOrderModel[]
): TTOrderSummaryRequestModel => {
  return {
    acceptConditions: summary.acceptConditions,
    billReference: summary.billReference,
    initialChargeDate: ConvertToISODateOnly(summary.initialChargeDate),
    productCode: edenredProducts.ticketTransporte,
    cardOrders: mapTTOrdersToCardOrders(ttOrders),
    orderTypeId: summary.orderTypeId,
    preOrderId: summary.preOrderId,
  }
}

export const parseTTOrderFormErrorKey = (error: string): string => {
  if (error.includes('employeeData')) {
    return error.split('.').slice(-1).toString() // make 1 level
  }
  if (error.includes('cardData')) {
    return error.split('.').slice(-2).toString() // make 2 level
  }
  if (error.includes('sendData')) {
    return error.replace('sendData', 'deliverySiteData').split('.').slice(-2).toString()
  }
  if (error.includes('deliverySiteId')) {
    return error.split('.').slice(-1).toString() // make 1 level
  }
  return error
}
