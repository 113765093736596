import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreSelect,
  OreSelectPlaceholder,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import { forms } from 'src/domain/translations'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { CollectiveModel } from 'src/Flex/Collectives/domain'
import { DeleteCollectiveDangerMessage } from './DeleteCollectiveDangerMessage'
import { DeleteCollectiveSSIDangerMessage } from './DeleteCollectiveSSIDangerMessage'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'

export type DeleteModalActions = {
  open: (id: string, hasActiveHires: boolean, isalud: boolean) => void
}

interface IProps {
  onValidation: (id: string, newCollectiveId: string) => void
  onConfirmation: (id: string, newCollectiveId: string) => Promise<boolean>
  getExcel: (id: string, newCollectiveId: string) => Promise<string>
  employeesAffected: number | undefined
  showSSIAlert: boolean
  collectives: CollectiveModel[]
  setEmployeesAffected: Dispatch<SetStateAction<number | undefined>>
}

export const DeleteCollectiveModal = forwardRef<DeleteModalActions, IProps>(
  (
    {
      onValidation,
      getExcel,
      onConfirmation,
      employeesAffected,
      showSSIAlert,
      collectives,
      setEmployeesAffected,
    },
    ref
  ): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false)
    const [id, setId] = useState<string>('')
    const [hasActiveHires, setHasActiveHires] = useState<boolean>(false)
    const [isalud, setIsalud] = useState<boolean>(false)
    const { t } = useTranslation()

    const [collectiveId, setCollectiveId] = useState<string>('')

    let collectivesList: {
      label: string
      value: string
    }[] = []

    if (collectives) {
      collectivesList = collectives
        .filter(collective => collective.products.length > 0)
        .map(collective => ({
          value: collective.id,
          label: collective.name,
        }))
    }

    useImperativeHandle(ref, () => ({
      open: async (
        id: string,
        hasActiveHires: boolean,
        isalud: boolean
      ): Promise<void> => {
        setId(id)
        setHasActiveHires(hasActiveHires)
        setIsalud(isalud)
        setOpen(true)
      },
    }))

    const saveAndClose = async (): Promise<void> => {
      onConfirmation(id, collectiveId)
      setOpen(false)
      setEmployeesAffected(undefined)
    }

    const validation = async (): Promise<void> => {
      onValidation(id, collectiveId)
    }

    const downloadFile = async () => {
      const link = document.createElement('a')
      const excel = await getExcel(id, collectiveId)
      link.href = `data:application/octet-stream;base64,${excel}`
      link.download = t(flexProductsTranslations.employeeContractAlert.excel)
      link.click()
      link.remove()
    }

    return (
      <OreModal
        open={open}
        handleOnClose={() => {
          setOpen(false)
          setEmployeesAffected(undefined)
        }}>
        <OreModalBox
          size="medium"
          handleOnClose={() => {
            setOpen(false)
            setEmployeesAffected(undefined)
          }}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {t(flexCollectivesTranslations.configure.delete.form.title)}
            </OreHeading>
            <OreStack space="large">
              <OreText align="left">
                {t(
                  flexCollectivesTranslations.configure.delete.form[
                    hasActiveHires ? (isalud ? 'isalud' : 'active') : 'inactive'
                  ]
                )}
              </OreText>
              <OreText align="left">
                {t(flexCollectivesTranslations.configure.delete.select.disclaimer)}
              </OreText>
              <OreText align="left">
                {t(flexCollectivesTranslations.configure.delete.select.disclaimer2)}
              </OreText>
              <OreSelect
                label={t(flexCollectivesTranslations.configure.delete.select.label)}
                onChange={e => setCollectiveId(e.target.value)}>
                <OreSelectPlaceholder
                  label={t(
                    flexCollectivesTranslations.configure.delete.select.placeholder
                  )}
                />
                {collectivesList
                  ? collectivesList.map(
                      o =>
                        id !== o.value && (
                          <option value={o.value} key={o.value}>
                            {o.label}
                          </option>
                        )
                    )
                  : null}
              </OreSelect>
              {employeesAffected !== undefined &&
              employeesAffected > 0 &&
              !showSSIAlert ? (
                <DeleteCollectiveDangerMessage
                  collectiveId={id}
                  newCollectiveId={collectiveId}
                  numberOfContracts={employeesAffected}
                  downloadFile={downloadFile}
                />
              ) : null}

              {employeesAffected !== undefined &&
              employeesAffected > 0 &&
              showSSIAlert ? (
                <DeleteCollectiveSSIDangerMessage
                  collectiveId={id}
                  newCollectiveId={collectiveId}
                  numberOfContracts={employeesAffected}
                  downloadFile={downloadFile}
                />
              ) : null}
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton
              onClick={() => {
                setEmployeesAffected(undefined)
                setOpen(false)
              }}
              size="small"
              category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            {employeesAffected !== undefined ? (
              <OreButton
                type="submit"
                onClick={saveAndClose}
                size="small"
                category={'danger'}>
                {t(forms.buttons.delete)}
              </OreButton>
            ) : (
              <OreButton
                onClick={validation}
                size="small"
                category={'primary'}
                disabled={collectiveId === ''}>
                {t(forms.buttons.delete)}
              </OreButton>
            )}
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
