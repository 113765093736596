import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { flexProductsTranslations } from '../../translations'
import { useEmployeContractAlert } from '../hooks'
import { edenredProducts } from 'src/domain/enum'
import { ProductDangerMessage } from './ProductDangerMessage'
import { PolicyDangerMessage } from './PolicyDangerMessage'
import { ProductWarningMessage } from './ProductWarningMessage'
import {
  PolicyModel,
  ProductConfigurationModels,
  ProductModel,
} from 'src/Flex/Products/domain'

interface EmployeeContractAlertProps<K extends keyof ProductConfigurationModels> {
  numberOfContracts: number
  activeChange: boolean | undefined
  deleteChange: boolean | undefined
  productType: edenredProducts
  policy?: PolicyModel
  product?: ProductConfigurationModels[K]
  collectiveId?: string
}

export const EmployeeContractAlert = <K extends keyof ProductConfigurationModels>({
  numberOfContracts,
  activeChange,
  deleteChange,
  productType,
  policy,
  product,
  collectiveId,
}: EmployeeContractAlertProps<K>): JSX.Element => {
  const { t } = useTranslation()
  const { getExcel, getExcelHealth, getExcelDeleteHealth } = useEmployeContractAlert()
  const [excel, setExcel] = useState<string>('')
  const downloadFile = async () => {
    const link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${excel}`
    link.download = t(flexProductsTranslations.employeeContractAlert.excel)
    link.click()
    link.remove()
  }

  const getExcelFromHook = async (): Promise<void> => {
    if (product) setExcel(await getExcel(productType, product, collectiveId))
  }
  const getExcelHealthFromHook = async (): Promise<void> => {
    if (policy) setExcel(await getExcelHealth(policy))
  }
  const getExcelDeleteHealthFromHook = async (): Promise<void> => {
    if (policy) setExcel(await getExcelDeleteHealth(policy.id ?? ''))
  }

  const getExcelToDownload = async (): Promise<void> => {
    if (policy === undefined) {
      getExcelFromHook()
    }
    if (policy !== undefined && !deleteChange) {
      getExcelHealthFromHook()
    }
    if (policy !== undefined && deleteChange) {
      getExcelDeleteHealthFromHook()
    }
  }

  useEffect(() => {
    if (numberOfContracts > 0) getExcelToDownload()
  }, [numberOfContracts])

  return (
    <>
      {numberOfContracts > 0 && policy === undefined ? (
        <ProductDangerMessage
          activeChange={activeChange}
          downloadFile={downloadFile}
          numberOfContracts={numberOfContracts}
          productType={productType}
          collectiveId={collectiveId}
        />
      ) : (
        <></>
      )}
      {numberOfContracts > 0 && policy !== undefined ? (
        <PolicyDangerMessage
          activeChange={activeChange}
          deleteChange={deleteChange}
          downloadFile={downloadFile}
          numberOfContracts={numberOfContracts}
        />
      ) : (
        <></>
      )}
      {numberOfContracts === 0 ? (
        <ProductWarningMessage deleteChange={deleteChange} />
      ) : (
        <></>
      )}
    </>
  )
}
