import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { clientHomeTranslation } from '../../../../domain/translations/client/home'
import { useAuth } from '../../../context/auth/AuthProvider'
import { OreHeading, OreText } from '@edenredespana/oreneta'
import { edenredProducts } from 'src/domain/enum'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { IProductsTabModel } from 'src/domain/models'
import { ClientHomeContainer } from './organism/ClientHomeContainer'

export const ClientHomePage = (): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const { state } = useLocation()
  const [productTab, setProductTab] = useState<edenredProducts>()

  useEffect(() => {
    if (state) {
      const tab = state as IProductsTabModel
      setProductTab(tab.navigateValue)
    }
  }, [state])

  return (
    <>
      <div className={cx('hero', { 'hero--home': true }, { 'hero--reduced': false })}>
        <div className="hero__content">
          <OreHeading align="left" size="title-md" as="h1" tone="neutral">
            <span className="hero__bullet"></span>
            {`${t(clientHomeTranslation.title)} ${user?.name?.split(' ')[0]}! 👋`}
          </OreHeading>
          <div className="mt-1">
            <OreText>{t(clientHomeTranslation.subtitle)}</OreText>
          </div>
        </div>
      </div>
      <ClientHomeContainer navigateValue={productTab} />
    </>
  )
}
