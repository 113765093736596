// arrow function
import { cardOrderTypeEnum, excelRequestType, orderType } from '../../../../domain/enum'

export const GetExcelOrderTypeRequest = (_orderType: number) => {
  if (_orderType == orderType.Card) return excelRequestType.CardsOptionalRecharge
  if (_orderType == orderType.Recharge) return excelRequestType.Recharge
  if (_orderType == orderType.Unload) return excelRequestType.Unload
  return excelRequestType.none
}

export const GetExcelFilenameByOrderType = (
  empty: boolean,
  _orderType: number
): string => {
  switch (_orderType) {
    case orderType.Recharge:
      return `ERES_Ticket_Restaurant_Fichero pedido recargas${
        !empty ? ' precargado' : ''
      }.xlsx`
    case orderType.Unload:
      return `ERES_Ticket_Restaurant_Fichero pedido descargas${
        !empty ? ' precargado' : ''
      }.xlsx`
    default:
      return `ERES_Ticket_Restaurant_Fichero pedido${!empty ? ' precargado' : ''}.xlsx`
  }
}
